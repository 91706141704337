import jwtDecode from "jwt-decode";

interface IDecodedJwt {
    iss: string; // authenticating server
    iat: number; // date created inepoch seconds
    sub: string; // technical identifier of user
    exp: number; // date expired epoch seconds
}

/**
 * Test if token is valid
 * @param {string} token
 * @returns {boolean}
 */
const isValid = (token: string): boolean => {
    const expired = isExpired(token);
    return !expired;
};

/**
 * Test if token is expired
 * @param {string} token
 * @returns {boolean}
 */
const isExpired = (token: string): boolean => {
    if (!token) return true;
    let decodedToken: IDecodedJwt = jwtDecode(token);
    return new Date().getTime() / 1000 > decodedToken.exp;
};

const toExport = {
    isValid,
    isExpired,
};

export default toExport;
