import React, { useState, useContext } from 'react';
import { Row, Col, Button, Input, Form, PageHeader } from 'antd';

// import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks';
import { GlobalContext } from 'components/GlobalContext';

export const UPDATE_VERIFIED_SOURCES = gql`
   mutation VerifySource($propertyId: String!, $address: String!){
        verifySource(input: {propertyId: $propertyId, address: $address}) {
           user {
               dealSources {
                   name
               }
           }
           errors
       }
   }
`

export const GET_USER_DEAL_SOURCES = gql`
    query GetUser($nanoid: String!){
        user(nanoid: $nanoid) {
            dealSources {
                name
            }
        }
    }
`

export const GET_NOTE_TO_VERIFY = gql`
    query GetNoteToVerify($source: String!){
        getNoteToVerify(source: $source) {
            nanoid
            sourceId
            sourceUrl
        }
    }
`

const VerifySource: React.FC = () => {
    const [form] = Form.useForm();
    const { state } = useContext(GlobalContext);
    const [sources, setSources] = useState({
        'Paperstac': { verified: false, address: '', propertyId: null },
        'Notes Direct': { verified: false, address: '', propertyId: null }
    })
    const [updateSources] = useMutation(UPDATE_VERIFIED_SOURCES);

    const { loading } = useQuery(GET_USER_DEAL_SOURCES, {
        variables: { nanoid: state.currentUser.nanoid }
    });

    const { loading: loadingPaperstacDeal, data: dataPaperstacDeal } = useQuery(GET_NOTE_TO_VERIFY, {
        variables: {
            source: 'Paperstac',
        }
    });
    const { loading: loadingNotesDirectDeal, data: dataNotesDirectDeal } = useQuery(GET_NOTE_TO_VERIFY, {
        variables: {
            source: 'Notes Direct',
        }
    });

    if (loadingPaperstacDeal || loadingNotesDirectDeal || loading) return <></>;
    const paperStacNote = dataPaperstacDeal.getNoteToVerify;
    const notesDirectNote = dataNotesDirectDeal.getNoteToVerify;

    return (<>
        <PageHeader title="Verify your access to third party deal sources" footer="Notesleuth doesn't make any commission on the sale of these assets nor does it have any affliation with them. This is here to verify you have access to the deal before we show them to you. This insures that we aren't displaying data that you don't have the NDA to see." />
        <br />
        <Form layout="inline" form={form}>
            <Row gutter={24} style={{ marginBottom: '20px' }}>
                <Col span={24}>
                    Go to this deal: &nbsp;<a href={paperStacNote?.sourceUrl} target="_BLANK">{paperStacNote?.sourceUrl}</a>
                </Col>
                <Col span={18}>
                    <Form.Item label={`Enter the street address`} initialValue={sources.Paperstac.address}>
                        <Input value={sources.Paperstac.address} onChange={(e) => setSources({ ...sources, Paperstac: { ...sources.Paperstac, propertyId: paperStacNote.sourceId, address: e.target.value } })} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Button onClick={() =>
                        updateSources({ variables: { propertyId: sources.Paperstac.propertyId, address: sources.Paperstac.address } })} >Verify Paperstac</Button>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    Go to this deal: &nbsp;<a href={notesDirectNote?.sourceUrl} target="_BLANK">{notesDirectNote?.sourceUrl}</a>
                </Col>
                <Col span={18}>
                    <Form.Item label={`Enter the street address`} initialValue={sources['Notes Direct'].address}>
                        <Input value={sources['Notes Direct'].address} onChange={(e) => setSources({ ...sources, ['Notes Direct']: { ...sources['Notes Direct'], propertyId: notesDirectNote.sourceId, address: e.target.value } })} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Button onClick={() =>
                        updateSources({ variables: { propertyId: sources['Notes Direct'].propertyId, address: sources['Notes Direct'].address } })} >Verify Notes Direct</Button>
                </Col>
            </Row>
        </Form>
    </>)
}

export default VerifySource
