import React, { useContext, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { INote, IPipeline } from "types";
import { camel2title, formatter, underscore2camel } from "helpers/utils";
// import { UserContext } from 'components/GlobalContext.';
import { GET_PIPELINE_FILTERED, UPDATE_PIPELINE } from "../CRUD/Note.crud";
import PipelineDealItem from "./PipelineDealItem";
import { Button, Drawer, Col, Row, Switch, Tag, Skeleton, Typography, Space, Dropdown, Menu } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { StarOutlined, InboxOutlined, SwapOutlined, SnippetsOutlined } from "@ant-design/icons";
import { FilterContext } from "components/FiltersContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Search from "antd/lib/input/Search";
import { FaEllipsisV } from "react-icons/fa";
import { AiOutlineUsb } from "react-icons/ai";
import Filters from "./Filters";

const { Title } = Typography;

const Deals: React.FC = () => {
    // const { state, dispatch } = useContext(GlobalContext);
    const [updatePipeline] = useMutation(UPDATE_PIPELINE);
    const { filters, setFilters, clearFilters } = useContext(FilterContext);
    const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);

    const { loading, data } = useQuery(GET_PIPELINE_FILTERED, {
        variables: {
            ...filters,
            maxInterestRate: filters.maxInterestRate / 100 ?? 0,
            minInterestRate: filters.minInterestRate / 100 ?? 0,
        },
        pollInterval: 60000,
    });

    const pipelineRecords: IPipeline[] = useMemo(() => {
        return data?.userPipeline.nodes || [];
    }, [data]);

    const notes: INote[] = useMemo(() => {
        return pipelineRecords.map((pipelineRecord: IPipeline) => pipelineRecord.note) || [];
    }, [pipelineRecords]);

    const grid = 0;

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 5,
        paddingBottom: "5px",
        margin: `0 0 ${grid}px 0`,
        textAlign: "left" as "left",

        // change background colour if dragging
        background: isDragging ? "" : "",
        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const getListStyle = (isDraggingOver: any) => ({
        borderRadius: "5px",
        margin: "0 5px",
        paddingTop: "0px",
        background: isDraggingOver ? "rgb(0,0,0,.2)" : "#001529",
        color: "#f1f1f1",
        // fontWeight: 'bold' as 'bold',
        padding: grid,

        textAlign: "left" as "left",
    });

    // if (!state.currentUser.nanoid) return <Redirect to={'/login'} />;

    const onDragEnd = async (dragItem: any) => {
        console.log("drag item", dragItem);
        const foundIndex = pipelineRecords.findIndex((x) => x.nanoid === dragItem.draggableId);
        pipelineRecords[foundIndex] = {
            ...pipelineRecords[foundIndex],
            dueDiligencePhase: dragItem.destination.droppableId,
        };

        await updatePipeline({
            variables: {
                nanoid: dragItem.draggableId,
                archived: pipelineRecords[foundIndex].archived,
                closedWon: pipelineRecords[foundIndex].closedWon,
                closedLost: pipelineRecords[foundIndex].closedLost,
                dueDiligencePhase: dragItem.destination.droppableId,
            },
            refetchQueries: [{ query: GET_PIPELINE_FILTERED }],
        });
    };

    const onSearch = (searchTerm: string) => {
        setFilters({ ...filters, searchTerm });
        // refetch(filters);
    };

    const handleBulkAction = async (e: any) => {
        const currentPhase = e.item.props["data-currentPhase"];
        const key = e.key;

        if (key === "archive") {
            await Promise.all(
                pipelineRecords
                    .filter((record) => record.dueDiligencePhase === currentPhase)
                    .map((filteredRecord) => {
                        return updatePipeline({
                            variables: {
                                nanoid: filteredRecord.nanoid,
                                archived: true,
                                closedWon: filteredRecord.closedWon,
                                closedLost: filteredRecord.closedLost,
                                dueDiligencePhase: filteredRecord.dueDiligencePhase,
                            },
                            refetchQueries: [{ query: GET_PIPELINE_FILTERED }],
                        });
                    })
            );
        }
        // refetch(filters);
    };

    const columnMenu = (currentPhase: string) => (
        <Menu onClick={handleBulkAction}>
            <Menu.Item data-currentPhase={currentPhase} key="archive" icon={<AiOutlineUsb />}>
                {" "}
                Archive All Visible
            </Menu.Item>
        </Menu>
    );

    const filteredByPhase = (phase: string) => pipelineRecords.filter((record) => record.dueDiligencePhase === phase);

    const dueDiligencePhase = ["inbox", "interested", "negotiating", "offer_accepted"];

    return (
        <>
            <Row gutter={24} justify="space-between">
                <Col xs={24} lg={6}>
                    <Title style={{ marginLeft: "2rem" }} level={4}>
                        Your Deal Flow
                    </Title>
                </Col>
                <Col xs={24} lg={18} style={{ display: "flex", justifyContent: "end" }}>
                    <Row>
                        <Switch
                            style={{ marginRight: "1rem", marginTop: ".25rem" }}
                            checkedChildren={"Archived"}
                            unCheckedChildren={"Archived"}
                            checked={filters.archived}
                            onChange={(checked: boolean) => setFilters({ ...filters, archived: checked })}
                        />
                        <Switch
                            style={{ marginRight: "1rem", marginTop: ".25rem" }}
                            checkedChildren={"Won"}
                            unCheckedChildren={"Won"}
                            checked={filters.closedWon}
                            onChange={(checked: boolean) => setFilters({ ...filters, closedWon: checked })}
                        />
                        <Switch
                            style={{ marginRight: "1rem", marginTop: ".25rem" }}
                            checkedChildren={"Lost"}
                            unCheckedChildren={"Lost"}
                            checked={filters.closedLost}
                            onChange={(checked: boolean) => setFilters({ ...filters, closedLost: checked })}
                        />
                    </Row>
                    <Row>
                        <Search
                            placeholder="Search by id, address, city, state"
                            onSearch={onSearch}
                            style={{ width: 300 }}
                        />{" "}
                        &nbsp;
                        <Button loading={loading} onClick={() => setFilterDrawerVisible(true)}>
                            <FilterOutlined /> Filters
                        </Button>
                        {/* <Button onClick={() => setSelectAll(true)}>Select All</Button> */}
                    </Row>
                </Col>
            </Row>
            <br />
            <Space direction="vertical" size="large" style={{ display: "flex" }}>
                <Row gutter={24} justify="end">
                    <Col xs={24}>
                        {Object.entries(filters).map(([key, value]: [any, any]) => {
                            if (key === "dueDiligencePhase") {
                                return null;
                            }
                            if (value === 0 || !value || (Array.isArray(value) && !value.length)) {
                                return null;
                            }
                            if (Array.isArray(value)) {
                                return (
                                    <Tag key={key}>
                                        {camel2title(key)}: {value.join(", ")}
                                    </Tag>
                                );
                            }
                            if (value === true || value === false) {
                                return <Tag>{camel2title(key)}</Tag>;
                            }
                            return (
                                <Tag key={key}>
                                    {" "}
                                    {camel2title(key)}: {value}
                                </Tag>
                            );
                        })}
                    </Col>
                </Row>

                {!loading && (
                    <Row gutter={24} style={{ minWidth: "1400px" }}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            {dueDiligencePhase.map((phase: string) => {
                                return (
                                    <Col
                                        span={24 / dueDiligencePhase.length}
                                        style={{ paddingLeft: "0", paddingRight: "0" }}
                                        key={phase}
                                    >
                                        <Droppable droppableId={phase}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    <Title level={5} style={{ color: "#f1f1f1", padding: "5px 10px" }}>
                                                        {phase === "inbox" && <InboxOutlined />}
                                                        {phase === "interested" && <StarOutlined />}
                                                        {phase === "negotiating" && <SwapOutlined />}
                                                        {phase === "offer_accepted" && <SnippetsOutlined />}{" "}
                                                        {camel2title(underscore2camel(phase))} (
                                                        {filteredByPhase(phase).length},{' '}
                                                        {formatter.format(filteredByPhase(phase).reduce(
                                                            (prev, next) =>  prev + (next.note.listPrice || 0),
                                                            0
                                                        ))}
                                                        )
                                                        {filteredByPhase(phase).length > 0 && (
                                                            <Dropdown.Button
                                                                trigger={["click"]}
                                                                buttonsRender={([leftButton, rightButton]) => {
                                                                    return [
                                                                        null,
                                                                        <Button
                                                                            style={{
                                                                                background: "transparent",
                                                                                color: "white",
                                                                                border: "0",
                                                                                textAlign: "right",
                                                                                paddingRight: ".5rem",
                                                                                paddingLeft: ".5rem",
                                                                            }}
                                                                        >
                                                                            <FaEllipsisV />
                                                                        </Button>,
                                                                    ];
                                                                }}
                                                                style={{ float: "right" }}
                                                                // onClick={() => {}}
                                                                overlay={columnMenu(phase)}
                                                            ></Dropdown.Button>
                                                        )}
                                                    </Title>

                                                    {filteredByPhase(phase).map((item, index) => (
                                                        <Draggable
                                                            key={item.nanoid}
                                                            draggableId={item.nanoid || index + ""}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <PipelineDealItem
                                                                        paramPipeline={item}
                                                                        key={item.nanoid}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                    {filteredByPhase(phase).length === 0 && (
                                                        <div style={{ padding: "10px" }}>
                                                            <i>Drag deals here</i>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Droppable>
                                    </Col>
                                );
                            })}
                        </DragDropContext>
                    </Row>
                )}
            </Space>

            {!loading && !notes.length && <>No notes match your criteria</>}
            {loading && (
                <Row>
                    <Col style={{ padding: "10px" }} span={24 / dueDiligencePhase.length}>
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                    </Col>
                    <Col style={{ padding: "10px" }} span={24 / dueDiligencePhase.length}>
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                    </Col>
                    <Col style={{ padding: "10px" }} span={24 / dueDiligencePhase.length}>
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                    </Col>
                    <Col style={{ padding: "10px" }} span={24 / dueDiligencePhase.length}>
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                    </Col>
                </Row>
            )}

            <Drawer
                title={
                    <>
                        Filters Button{" "}
                        <Button type="link" onClick={clearFilters}>
                            Reset
                        </Button>
                    </>
                }
                placement="right"
                closable={false}
                onClose={() => {
                    setFilterDrawerVisible(false);
                }}
                visible={filterDrawerVisible}
            >
                <Filters />
            </Drawer>
        </>
    );
};

export default Deals;
