import React from "react";
import { Bar } from "@ant-design/charts";
import { INote } from "types";
import { formatter } from "helpers/utils";

const BalanceBars: React.FC<{ note: INote }> = (props: { note: INote }) => {
    const note = props.note;

    const config = {
        data: [
            {
                type: "Asset Value",
                value: note.propertyValueSource,
            },
            {
                type: "UPB",
                value: note.upb,
            },
            {
                type: "Investment",
                value: note.listPrice,
            },
        ],
        maxBarWidth: 50,
        xField: "value",
        yField: "type",
        colorField: "type", // or seriesField in some cases
        color: ({ type }: any) => {
            if (type === "Asset Value") return "#004c6d";
            if (type === "UPB") return "#346888";
            if (type === "Investment") return "#5886a5";
            return "#5886a5";
        },
        label: {
            content: ({ type }: any) => {
                if (type === "Asset Value") return `${formatter.format(note.propertyValueSource || 0)}`;
                if (type === "UPB" && note.propertyValueSource)
                    return ((100 * (note.upb || 0)) / (note.propertyValueSource || 0)).toFixed(2) + "% LTV";
                if (type === "Investment") {
                    let rtn = "";
                    rtn += ((100 * (note.listPrice || 0)) / (note.upb || 0)).toFixed(2) + "% ITB, ";

                    if (note.propertyValueSource)
                        rtn += ((100 * (note.listPrice || 0)) / (note.propertyValueSource || 0)).toFixed(2) + "% ITV";
                    return rtn;
                }
                return "";
            },
        },
    };
    return <Bar {...config} />;
};
export default BalanceBars;
