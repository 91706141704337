import React, { useState, useContext } from 'react';
import { Form, Input, Button, Row, Col, PageHeader, Space } from 'antd'
import { useMutation } from 'react-apollo';
import { LOGIN_USER } from 'CRUD/User.crud';
import { GlobalContext } from 'components/GlobalContext';
import { Redirect, Link } from 'react-router-dom';
import FilterContextProvider, { FilterContext } from 'components/FiltersContext';

const Login: React.FC<{}> = () => {
    const [loginUser] = useMutation(LOGIN_USER);
    const [form] = Form.useForm();

    const [componentState, setComponentState] = useState({
        password: '',
        email: '',
        isLoading: false,
        loginSuccess: false,
    });
    const { dispatch } = useContext(GlobalContext);
    const { clearFilters } = useContext(FilterContext);


    const handleLogin = () => {

        setComponentState({ ...componentState, isLoading: true })
        loginUser({ variables: componentState }).then((resp) => {
            localStorage.setItem('auth-token', resp.data.loginUser.token)
            clearFilters();
            dispatch({
                type: 'UPDATE_CURRENT_USER',
                payload: {
                    currentUser: resp.data.loginUser.user
                }
            })

            setComponentState({ password: '', email: '', isLoading: false, loginSuccess: true });

        }).catch((err) => {
            setComponentState({ ...componentState, isLoading: false })
            console.log(err)
        })
    }

    const handleChange = (e: any, field: string) => {
        setComponentState({ ...componentState, [field]: e.target.value });
    }

    const loginForm = (
        <FilterContextProvider>
            <PageHeader title={'Login'} />
            <Row>
                <Col xs={24} lg={24} xl={{ span: 8, offset: 8 }}>

                    <Form form={form} onFinish={handleLogin} layout={'vertical'}>
                        {/* <Input onChange={(e) => handleChange(e, 'email')} /> */}
                        <Form.Item label="Email">
                            <Input onChange={(e) => handleChange(e, 'email')} />
                        </Form.Item>
                        <Form.Item label="Password">
                            <Input type="password" value={componentState.password} onChange={(e) => handleChange(e, 'password')} />
                        </Form.Item>
                        <Space >
                            <Button loading={componentState.isLoading} type="primary" htmlType="submit" >Login</Button>
                            <Link to={'/password/forgot'} >Forgot Password</Link>
                        </Space>

                    </Form>
                </Col>
            </Row >
            </FilterContextProvider>

    )

    const redirect = <Redirect to="/deals" />;

    if (componentState.loginSuccess) return redirect;
    else return loginForm;
}

export default Login
