import React, { useState } from "react";
import { Line } from "@ant-design/charts";
import { INote } from "types";
import Finance from "tvm-financejs";
import moment from "moment";
let finance = new Finance();

const RemainingAmortization: React.FC<{ note: INote }> = (props: { note: INote }) => {
    const { note } = props;
    const originalAmortization = note.originalAmortization || 0;
    const interestRate = note.interestRate || 0;
    const dataPopulation = [];
    const now = moment(Date.now()).valueOf();

    for (let i = originalAmortization; i >= 0; i--) {
        const pointDate = moment(note.originationDate).add(i, "M").valueOf();
        let value = 0;
        if (!note.interestOnlyLoan)
            value = value =
                (-1 * finance.PV(interestRate / 12, originalAmortization - i, note.principalAndInterestPayment, 0, 1)) |
                0;
        else value = note.upb || 0;
        dataPopulation.push({
            date: moment(note.originationDate).add(i, "M").format("YYYY-MM-DD"),
            value,
            category: now - pointDate < 0 ? "Future Balance" : "Past Balance",
        });
    }

    const [data] = useState(dataPopulation);

    const config = {
        data,
        // padding: 'auto',
        xField: "date",
        yField: "value",
        xAxis: {
            type: "timeCat",
            tickCount: originalAmortization / 12,
        },
        color: ["blue", "lightgray"],
        seriesField: "category",
        smooth: true,
    };
    return <Line {...config} />;
};
export default RemainingAmortization;
