import gql from 'graphql-tag'

export const GET_FILTERS = gql`
    query GetFilters {
        filters {
            sellers
            legalStatus
            states
            propertyTypes
            noteTypes
        }
    }
`
