import React from "react";
import { useQuery } from "@apollo/react-hooks";
import Moment from "react-moment";
import { Row, Col, Tooltip, Menu, Dropdown, Skeleton, Card, Statistic, Button, Steps } from "antd";
import { GET_PIPELINE_DEAL } from "../CRUD/Note.crud";
import { useParams, Link } from "react-router-dom";

// import { UserContext } from 'components/GlobalContext.';
import { INote } from "types";
import {
    StarOutlined,
    InboxOutlined,
    SwapOutlined,
    SnippetsOutlined,
    CheckCircleOutlined,
    DislikeOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import { camel2title, formatter, stateNameToAbbreviation, underscore2camel } from "helpers/utils";
import { ordinal } from "../helpers/utils";
import { IPipeline } from "../types/index";
import { useMutation } from "react-apollo";
import { UPDATE_PIPELINE } from "../CRUD/Note.crud";
import TvmCalculator from "./TvmCalculator";
import RemainingAmortization from "./Visuals/RemainingAmortization";
import BalanceBars from "./Visuals/BalanceBars";
import Comments from "./Comments";
import {
    FaBuilding,
    FaCalculator,
    FaCalendarDay,
    FaDollarSign,
    FaExternalLinkAlt,
    FaFile,
    FaHandHoldingUsd,
    FaMapMarkedAlt,
    FaRegChartBar,
} from "react-icons/fa";
const { Step } = Steps;

const PipelineViewFull: React.FC<{ paramNote?: INote }> = (props: { paramNote?: INote }) => {
    const { id } = useParams<{ id: string }>();
    const [updatePipeline] = useMutation(UPDATE_PIPELINE);

    // const [updateNote] = useMutation(UPDATE_NOTE);
    // const { state, dispatch } = useContext(GlobalContext);
    const { loading, error, data, refetch } = useQuery(GET_PIPELINE_DEAL, { variables: { nanoid: id } });

    if (loading) return <Skeleton active />;
    if (error) console.error(`ERROR: ${error}`);
    if (error) return <>Oh noes! We've encountered an error. Please try again later</>;
    if (!data?.pipeline?.note) return <>No note found</>;

    const note: any = data.pipeline.note;
    const pipelineRecord: Partial<IPipeline> = data.pipeline;

    function handleButtonClick(e: any) {
        console.log("click left button", e);
        // auto move to next phase
    }

    function handleMenuClick(e: any) {
        const { closedWon, closedLost, archived, dueDiligencePhase } = pipelineRecord;
        if (["closedWon", "closedLost", "archived"].includes(e.key)) {
            updatePipeline({
                variables: {
                    nanoid: pipelineRecord.nanoid,
                    closedWon,
                    closedLost,
                    archived,
                    dueDiligencePhase,
                    [e.key]: true,
                },
            });
        } else {
            updatePipeline({
                variables: { nanoid: pipelineRecord.nanoid, closedWon, closedLost, archived, dueDiligencePhase: e.key },
            });
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="inbox" icon={<InboxOutlined />}>
                Move to inbox
            </Menu.Item>
            <Menu.Item key="interested" icon={<StarOutlined />}>
                Mark as interested
            </Menu.Item>
            <Menu.Item key="negotiating" icon={<SwapOutlined />}>
                Mark as negotiating
            </Menu.Item>
            <Menu.Item key="offer_accepted" icon={<SnippetsOutlined />}>
                Mark as offer accepted
            </Menu.Item>
            <Menu.Item key="closedWon" icon={<CheckCircleOutlined />}>
                Mark as closed won
            </Menu.Item>
            <Menu.Item key="closedLost" icon={<CheckCircleOutlined />}>
                Mark as closed lost
            </Menu.Item>
            <Menu.Item key="archived" icon={<DislikeOutlined />}>
                Archive
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <React.Fragment key={note.nanoid}>
                <Row
                    style={{
                        background: "#001529",
                        padding: "15px 30px",
                        color: "rgba(255, 255, 255, 0.75)",
                        borderRadius: "5px",
                        marginLeft: "30px",
                        marginRight: "30px",
                        position: "relative",
                        top: "20px",
                    }}
                    justify={"space-between"}
                >
                    {/* <Col xs={4} md={3} lg={1} style={{ fontSize: '30px', color: 'gold' }}>
                    <StarOutlined />
                </Col> */}
                    <Col xs={24} xxl={16} style={{ fontSize: "16px" }}>
                        {`${note.streetAddress}`}
                        <i>{note.availability?.includes("Pending") && ", Pending Sale"}</i>
                        <br />
                        {`${note.city}, ${note.state} ${note.zip}`}
                        <br />
                        {ordinal(note.lienPosition)} Position {note.performance} {note.noteType}
                        <br />
                        {note.listingType.includes("Pool") ? (
                            <>
                                Part of a <Link to={`/for-sale/source/${note.sourceId}`}>Pool</Link>
                                <br />
                            </>
                        ) : (
                            ""
                        )}
                        {note.seller ? `Listed by ${note.seller}` : ""}{" "}
                        {note.listDate && (
                            <>
                                on <Moment format="MMM Do, YYYY">{note.listDate}</Moment>
                            </>
                        )}
                        {note.sourceLastUpdated && (
                            <>
                                (last update: <Moment format="MMM Do, YYYY">{note.sourceLastUpdated}</Moment>)
                            </>
                        )}
                    </Col>

                    <Col
                        xs={24}
                        xxl={3}
                        style={{ fontSize: "30px", display: "flex", justifyContent: "end", alignItems: "center" }}
                    >
                        <Button
                            style={{ marginRight: "10px" }}
                            type="primary"
                            target="_BLANK"
                            href={note.sourceUrl}
                            rel="noreferrer"
                        >
                            Make offer on {note.source} <FaExternalLinkAlt style={{ marginLeft: "10px" }} />
                        </Button>
                        <Dropdown.Button onClick={handleButtonClick} overlay={menu}>
                            {pipelineRecord.dueDiligencePhase === "inbox" && <InboxOutlined />}
                            {pipelineRecord.dueDiligencePhase === "interested" && <StarOutlined />}
                            {pipelineRecord.dueDiligencePhase === "negotiating" && <SwapOutlined />}
                            {pipelineRecord.dueDiligencePhase === "offer_accepted" && <SnippetsOutlined />}
                            {pipelineRecord.dueDiligencePhase === "closed" && <CheckCircleOutlined />}
                            {pipelineRecord.dueDiligencePhase === "archived" && <DislikeOutlined />}
                            {camel2title(underscore2camel(pipelineRecord.dueDiligencePhase || ""))}
                        </Dropdown.Button>
                    </Col>
                </Row>
                <div
                    style={{
                        border: "1px solid rgba(0, 0, 0, 0.15)",
                        paddingTop: "30px",
                        paddingLeft: "10px",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        zIndex: 0,
                    }}
                >
                    {/* <Tabs
                    tabPosition={"left"}
                    style={{
                        border: "1px solid rgba(0, 0, 0, 0.15)",
                        paddingTop: "30px",
                        marginBottom: "20px",
                        borderRadius: "5px",
                        zIndex: 0,
                    }}
                >
                    <Tabs.TabPane tab="Deal Info" key="1"> */}
                    <Row gutter={24}>
                        <Col lg={24} xxl={16}>
                            <Row gutter={8}>
                                <Col xxl={12} lg={24} style={{ marginBottom: "10px" }}>
                                    <Card
                                        style={{ boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.15)" }}
                                        size="small"
                                        title={
                                            <>
                                                <FaFile
                                                    size={24}
                                                    style={{ verticalAlign: "middle", margin: "3px 5px" }}
                                                />{" "}
                                                About the Deal
                                            </>
                                        }
                                    >
                                        <Row>
                                            <Col span={12}>
                                                <p>
                                                    <Statistic
                                                        title="Asking Price"
                                                        value={note.listPrice}
                                                        precision={2}
                                                        prefix="$"
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />{" "}
                                                    {note.negotiationType}
                                                </p>
                                                <p>
                                                    <Statistic
                                                        title="Unpaid Balance"
                                                        value={note.upb}
                                                        precision={2}
                                                        prefix="$"
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>

                                                <p>
                                                    <Statistic
                                                        title="Discount off UPB"
                                                        value={note.upb}
                                                        formatter={() => (
                                                            <>{formatter.format(note.listPrice - note.upb)}</>
                                                        )}
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                                {note.noOfBids >= 0 && !!note.noOfBids && (
                                                    <p>
                                                        <Statistic
                                                            title="No of Bids"
                                                            value={note.noOfBids}
                                                            valueStyle={{ fontSize: "1.2rem" }}
                                                        />
                                                    </p>
                                                )}
                                                {!!note.currentBid && note.currentBid >= 0 && (
                                                    <p>
                                                        <Statistic
                                                            title="Current Bid"
                                                            value={note.currentBid}
                                                            prefix="$"
                                                            valueStyle={{ fontSize: "1.2rem" }}
                                                        />
                                                    </p>
                                                )}
                                            </Col>
                                            <Col span={12}>
                                                <p>
                                                    <Statistic
                                                        title="Instrument"
                                                        value={note.noteType}
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                                <p>
                                                    <Statistic
                                                        title="Position"
                                                        value={ordinal(note.lienPosition)}
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                                <p>
                                                    <Statistic
                                                        title="Performance"
                                                        value={note.performance}
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                                <p>
                                                    <Statistic
                                                        title="Legal Status"
                                                        formatter={() => (
                                                            <>
                                                                {note.legalStatus &&
                                                                    !["None", "Performing"].includes(
                                                                        note.legalStatus
                                                                    ) && (
                                                                        <WarningOutlined style={{ color: "red" }} />
                                                                    )}{" "}
                                                                {note.legalStatus || "--"}
                                                            </>
                                                        )}
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                                <p>
                                                    <Statistic
                                                        title="Foreclosure Laws"
                                                        formatter={() => (
                                                            <>
                                                                <a
                                                                    target="_blank"
                                                                    href={`https://foreclosurelaw.org/${note.state.replace(
                                                                        " ",
                                                                        "_"
                                                                    )}_Foreclosure_Law.htm`}
                                                                >
                                                                    Check foreclosure laws
                                                                </a>
                                                            </>
                                                        )}
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xxl={12} lg={24} style={{ marginBottom: "10px" }}>
                                    <Card
                                        style={{ minHeight: "350px", boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.15)" }}
                                        size="small"
                                        title={
                                            <>
                                                <FaCalculator
                                                    size={24}
                                                    style={{ verticalAlign: "middle", margin: "3px 5px" }}
                                                />{" "}
                                                Note Terms
                                            </>
                                        }
                                    >
                                        <Row>
                                            <Col span={12}>
                                                <p>
                                                    <Statistic
                                                        title="Coupon Rate"
                                                        value={note.interestRate * 100}
                                                        precision={2}
                                                        suffix="%"
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />{" "}
                                                    {note.interestOnlyLoan && "Interest Only"}
                                                </p>
                                                <p>
                                                    <Statistic
                                                        title="Principal and Interest"
                                                        value={note.principalAndInterestPayment || "--"}
                                                        precision={2}
                                                        prefix="$"
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                                <p>
                                                    <Statistic
                                                        title="Escrow & Impounds"
                                                        value={note.escrowImpounds || "--"}
                                                        precision={2}
                                                        prefix="$"
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                                <p>
                                                    <Statistic
                                                        title="Payments Remaining"
                                                        value={note.paymentsRemaining}
                                                        formatter={() => (
                                                            <>
                                                                {note.originalAmortization - note.paymentsRemaining <
                                                                    12 && (
                                                                    <Tooltip title="Typically you want at least 12 months of seasoning">
                                                                        <WarningOutlined style={{ color: "red" }} />
                                                                    </Tooltip>
                                                                )}{" "}
                                                                {note.paymentsRemaining || "--"} of{" "}
                                                                {note.originalAmortization || "--"}{" "}
                                                            </>
                                                        )}
                                                        precision={2}
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                            </Col>
                                            <Col span={12}>
                                                <p>
                                                    <Statistic
                                                        title="Original Balance"
                                                        value={note.originalBalance || "--"}
                                                        precision={2}
                                                        prefix="$"
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                                <p>
                                                    <Statistic
                                                        title="Total Monthly Pmt"
                                                        value={note.totalMonthlyLoanPayment || "--"}
                                                        precision={2}
                                                        prefix="$"
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                                <p>
                                                    <Statistic
                                                        title="Accrued Late Charges"
                                                        formatter={() => (
                                                            <>
                                                                {note.accruedLateCharges > 0 && (
                                                                    <WarningOutlined style={{ color: "red" }} />
                                                                )}{" "}
                                                                {note.accruedLateCharges
                                                                    ? formatter.format(note.accruedLateCharges)
                                                                    : "$ --"}
                                                            </>
                                                        )}
                                                        valueStyle={{ fontSize: "1.2rem" }}
                                                    />
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xxl={12} lg={24} style={{ marginBottom: "10px" }}>
                                    <Card
                                        style={{ minHeight: "575px", boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.15)" }}
                                        size="small"
                                        title={
                                            <>
                                                <FaMapMarkedAlt
                                                    size={24}
                                                    style={{ verticalAlign: "middle", margin: "3px 5px" }}
                                                />{" "}
                                                Property Map
                                            </>
                                        }
                                    >
                                        <iframe
                                            title="Property Map"
                                            width="100%"
                                            height="500"
                                            id="gmap_canvas"
                                            src={`https://maps.google.com/maps?q=${note.streetAddress} ${note.city} ${note.state} ${note.zip}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                                        ></iframe>
                                    </Card>
                                </Col>

                                {/* <Card
                                        size="small"
                                        title={
                                            <>
                                                <FaMapMarkedAlt
                                                    size={24}
                                                    style={{ verticalAlign: "middle", margin: "3px 5px" }}
                                                />{" "}
                                                Property Address/APN
                                            </>
                                        }

                                    >
                                        <p>
                                            <a target="_BLANK" href={note.googleUrl}>
                                                {`${note.streetAddress}`}
                                                <br /> {`${note.city}, ${note.state} ${note.zip}`}
                                            </a>
                                        </p>
                                        <p>County: {note.county || "--"}</p>
                                        <p>APN/Tax ID: {note.propertyTaxId || "--"}</p>
                                    </Card> */}
                                <Col xxl={12} lg={24} style={{ marginBottom: "10px" }}>
                                    <Card
                                        style={{ minHeight: "575px", boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.15)" }}
                                        size="small"
                                        title={
                                            <>
                                                <FaBuilding
                                                    size={24}
                                                    style={{ verticalAlign: "middle", margin: "3px 5px" }}
                                                />{" "}
                                                Property Attributes
                                            </>
                                        }
                                    >
                                        <Statistic
                                            title="Value"
                                            value={note.propertyValueSource}
                                            precision={0}
                                            prefix="$"
                                            valueStyle={{ fontSize: "1.2rem" }}
                                        />

                                        <p>
                                            {note.propertyValueSourceType && <>({note.propertyValueSourceType})</>}{" "}
                                            {note.propertyValueSourceDate && <>({note.propertyValueSourceDate})</>}
                                        </p>
                                        <p>
                                            <Statistic
                                                title="Property"
                                                value={`${note.occupancyStatus || "--"} ${note.propertyType || "--"}`}
                                                valueStyle={{ fontSize: "1.2rem" }}
                                            />
                                        </p>
                                        <p>
                                            <Statistic
                                                title="County"
                                                formatter={() => (
                                                    <>
                                                        {note.county ? (
                                                            <a
                                                                target="_blank"
                                                                href={`https://publicrecords.netronline.com/state/${stateNameToAbbreviation(
                                                                    note.state
                                                                )}/county/${note.county
                                                                    .toLowerCase()
                                                                    .replace(" ", "_")}`}
                                                            >
                                                                {note.county}
                                                            </a>
                                                        ) : (
                                                            "--"
                                                        )}
                                                    </>
                                                )}
                                                valueStyle={{ fontSize: "1.2rem" }}
                                            />
                                        </p>
                                        <p>
                                            <Statistic
                                                title="APN/Tax ID"
                                                value={note.propertyTaxId || "--"}
                                                valueStyle={{ fontSize: "1.2rem" }}
                                            />
                                        </p>

                                        {(note.judicial || note.nonJudicial) && (
                                            <p>
                                                <Statistic
                                                    title="Foreclosure Procedure"
                                                    formatter={() => (
                                                        <>
                                                            {note.judicial ? "Judicial" : ""}{" "}
                                                            {note.judicial && note.nonJudicial && "&"}{" "}
                                                            {note.nonJudicial ? "Non-Judicial" : ""}
                                                        </>
                                                    )}
                                                    valueStyle={{ fontSize: "1.2rem" }}
                                                />
                                            </p>
                                        )}
                                    </Card>
                                </Col>

                                <Col xxl={12} lg={24} style={{ marginBottom: "10px" }}>
                                    <Card
                                        style={{ boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.15)" }}
                                        size="small"
                                        title={
                                            <>
                                                <FaHandHoldingUsd
                                                    size={24}
                                                    style={{ verticalAlign: "middle", margin: "3px 5px" }}
                                                />{" "}
                                                Current Servicer
                                            </>
                                        }
                                    >
                                        <p>
                                            <Statistic
                                                title="Servicer"
                                                formatter={() => <>{note.servicer || "--"}</>}
                                                valueStyle={{ fontSize: "1.2rem" }}
                                            />
                                        </p>
                                        <p>
                                            <Statistic
                                                title="Servicer Loan ID"
                                                formatter={() => <>{note.loanId || "--"}</>}
                                                valueStyle={{ fontSize: "1.2rem" }}
                                            />
                                        </p>
                                    </Card>
                                </Col>
                                <Col xxl={12} lg={24} style={{ marginBottom: "10px" }}>
                                    <Card
                                        style={{ boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.15)" }}
                                        size="small"
                                        title={
                                            <>
                                                <FaCalendarDay
                                                    size={24}
                                                    style={{ verticalAlign: "middle", margin: "3px 5px" }}
                                                />{" "}
                                                Timeline
                                            </>
                                        }
                                    >
                                        <Row>
                                            <Steps progressDot current={3}>
                                                <Step
                                                    description={
                                                        <Statistic
                                                            title="Originated"
                                                            formatter={() => (
                                                                <>
                                                                    {note.originationDate ? (
                                                                        <Moment format="MMM Do, YYYY">
                                                                            {note.originationDate}
                                                                        </Moment>
                                                                    ) : (
                                                                        <>--</>
                                                                    )}
                                                                </>
                                                            )}
                                                            valueStyle={{ fontSize: "1.2rem" }}
                                                        />
                                                    }
                                                />
                                                <Step
                                                    description={
                                                        <Statistic
                                                            title="Last Payment Recieved"
                                                            formatter={() => (
                                                                <>
                                                                    {note.lastPaymentReceivedDate ? (
                                                                        <Moment format="MMM Do, YYYY">
                                                                            {note.lastPaymentReceivedDate}
                                                                        </Moment>
                                                                    ) : (
                                                                        <>--</>
                                                                    )}
                                                                </>
                                                            )}
                                                            valueStyle={{ fontSize: "1.2rem" }}
                                                        />
                                                    }
                                                />
                                                <Step
                                                    description={
                                                        <Statistic
                                                            title="Next Payment Scheduled"
                                                            formatter={() => (
                                                                <>
                                                                    {note.nextPaymentDate ? (
                                                                        <Moment format="MMM Do, YYYY">
                                                                            {note.nextPaymentDate}
                                                                        </Moment>
                                                                    ) : (
                                                                        <>--</>
                                                                    )}
                                                                </>
                                                            )}
                                                            valueStyle={{ fontSize: "1.2rem" }}
                                                        />
                                                    }
                                                />

                                                <Step
                                                    description={
                                                        <Statistic
                                                            title="Maturity Date"
                                                            formatter={() => (
                                                                <>
                                                                    {note.maturityDate ? (
                                                                        <Moment format="MMM Do, YYYY">
                                                                            {note.maturityDate}
                                                                        </Moment>
                                                                    ) : (
                                                                        <>--</>
                                                                    )}
                                                                </>
                                                            )}
                                                            valueStyle={{ fontSize: "1.2rem" }}
                                                        />
                                                    }
                                                />
                                            </Steps>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xxl={12} lg={24} style={{ marginBottom: "10px" }}>
                                    <Card
                                        style={{ boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.15)" }}
                                        size="small"
                                        title={
                                            <>
                                                <FaRegChartBar
                                                    size={24}
                                                    style={{ verticalAlign: "middle", margin: "3px 5px" }}
                                                />{" "}
                                                Relative Pricing
                                            </>
                                        }
                                    >
                                        <BalanceBars note={note} />
                                    </Card>
                                </Col>
                                <Col xxl={12} lg={24} style={{ marginBottom: "10px" }}>
                                    <Card
                                        style={{ boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.15)" }}
                                        size="small"
                                        title={
                                            <>
                                                <FaDollarSign
                                                    size={24}
                                                    style={{ verticalAlign: "middle", margin: "3px 5px" }}
                                                />{" "}
                                                Amortization Curve
                                            </>
                                        }
                                    >
                                        <RemainingAmortization note={note} />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} lg={12} xxl={8}>
                            <TvmCalculator note={note} />
                        </Col>
                    </Row>
                </div>
                {/* </Tabs.TabPane> */}
                {/* <Tabs.TabPane tab="Public Records" key="2">
                        <Row gutter={24}>
                            <Col xs={24} lg={24}>
                                <PublicRecords publicRecords={note.publicRecord} noteId={note.nanoid} />
                            </Col>
                        </Row>
                    </Tabs.TabPane> */}
                {/* <Tabs.TabPane tab="Checklist" key="3">
                        Coming soon...
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Buy/Sell Simulator" key="4">
                        Coming soon...
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Market Info" key="5">
                        Coming soon...
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Crime Info" key="6">
                        Coming soon...
                    </Tabs.TabPane>
                </Tabs> */}
            </React.Fragment>
            {/* <Col lg={24} md={24} xs={24} >
            <iframe
                src="https://www.google.com/maps/embed?pb=!4v1610821553614!6m8!1m7!1svcKmKbseBGAmkA2DwZqmzw!2m2!1d31.56917839895393!2d-97.16484578651772!3f134.72214!4f0!5f0.7820865974627469"
                width="600"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen={true}
                aria-hidden="false"
                tabIndex={0}></iframe>
        </Col> */}
            <Comments pipelineRecord={pipelineRecord} onSubmit={refetch} />

        </>
    );
};

export default PipelineViewFull;
