import { useMemo, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Card, Row, Col, Space } from 'antd';
import { IUser } from 'types'
import { GET_USERS, DELETE_USER } from '../CRUD/User.crud';
import { Link } from 'react-router-dom';
import Gravatar from 'react-gravatar'
import Meta from 'antd/lib/card/Meta';



// import { UserContext } from 'components/GlobalContext.';

const UsersList = () => {
    const { loading, error, data, refetch } = useQuery(GET_USERS);
    // const [updateUser] = useMutation(UPDATE_USER, { refetchQueries: mutationResult => [{ query: GET_USERS }] });
    const [deleteUser] = useMutation(DELETE_USER, { refetchQueries: mutationResult => [{ query: GET_USERS }] });
    const users: IUser[] = useMemo(() => { return data?.users || [] }, [data])

    useEffect(() => {
        refetch()
    }, [])
    // if (loading) return <>Loading...</>
    // if (error) return <>{`error.message: ${error.message}`}</>

    return useMemo(() => (
        <Row gutter={24}>
            {loading && <>Loading...</>}
            {error && <>{`Error: ${error.message}`}</>}
            {!loading && !users.length && <>No users to display</>}
            {!!users.length && users.map((user: IUser) => {
                return (
                    <Col xs={24} md={24} lg={10} xxl={6} key={user.nanoid}>
                        <Space>
                            <Card title={<Link to={`/user/${user.nanoid}`}>{user.firstName} {user.lastName}</Link>} extra={<a href="#" onClick={(e) => {
                                e.preventDefault();
                                deleteUser({ variables: { nanoid: user.nanoid } });
                            }}>x</a>} style={{ width: 300 }}>
                                <Meta
                                    avatar={<Gravatar email={user.email || ''} />}
                                    title={`${user.firstName} ${user.lastName}`}
                                    description={user.email}
                                />

                            </Card>
                        </Space>
                    </Col>)
            })}
        </Row>), [users, deleteUser, error, loading])
}

export default UsersList
