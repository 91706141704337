import { Modal, Input, Button, Comment, Form } from 'antd';
import { CREATE_COMMENT, DELETE_COMMENT } from 'CRUD/Note.crud';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { useMutation } from 'react-apollo';
import Gravatar from 'react-gravatar';
import Moment from 'react-moment';
import { IComment, IPipeline } from 'types';
import { GlobalContext } from './GlobalContext';
const { confirm } = Modal;
const { TextArea } = Input;


const Editor = ({ onChange, onSubmit, submitting, value, placeholder }: { onChange: any, onSubmit: any, submitting: any, value: any, placeholder: string }) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} placeholder={placeholder} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                Add Comment
            </Button>
        </Form.Item>
    </>
);

const Comments: React.FC<{ pipelineRecord: Partial<IPipeline>, onSubmit: Function }> = (props: { pipelineRecord: Partial<IPipeline>, onSubmit: Function }) => {
    const { state } = useContext(GlobalContext);
    const [createComment] = useMutation(CREATE_COMMENT);
    const [deleteComment] = useMutation(DELETE_COMMENT);
    const [commentInput, setComment] = useState<{ submitting: boolean, text: string }>({ submitting: false, text: '' })

    function showPromiseConfirm(id: number) {
        confirm({
            title: 'Do you want to delete this comment?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            async onOk() {
                await deleteComment({ variables: { id } });
                props.onSubmit();
                // return new Promise((resolve, reject) => {
                //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                // }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }

    return (
        <>
            {props.pipelineRecord.comments?.map((commentItr: IComment) => {
                return (
                    <Comment
                        key={commentItr.id}
                        className={'showonhover'}
                        // actions={actions}
                        author={<span>{commentItr.user.firstName} {commentItr.user.lastName}</span>}
                        avatar={
                            <Gravatar email={commentItr.user.email || ''} />
                        }
                        content={
                            <p>
                                {commentItr.text}
                            </p>
                        }
                        datetime={<>
                            <Moment format="MMM Mo YYYY h:mmA">
                                {commentItr.createdAt}
                            </Moment>
                            {commentItr.user.id === state.currentUser.id && <a className={'showme'} style={{ paddingLeft: '10px' }} onClick={() => showPromiseConfirm(commentItr.id)}>Remove</a>}
                        </>}
                    />
                )
            })}
            <Comment
                avatar={
                    <Gravatar email={state.currentUser.email || ''} />
                }
                content={
                    <Editor
                        onChange={(e: any) => {
                            setComment({ ...commentInput, text: e.target.value })
                        }}
                        onSubmit={async (e: any) => {
                            setComment({ ...commentInput, submitting: true })
                            await createComment({ variables: { pipelineNanoid: props.pipelineRecord.nanoid, userNanoid: state.currentUser.nanoid, text: commentInput.text } })
                            setComment({ submitting: false, text: '' })
                            props.onSubmit();
                        }}
                        submitting={commentInput.submitting}
                        value={commentInput.text}
                        placeholder={"Type a comment here"}
                    />
                }
            />
        </>
    )
}

export default Comments
