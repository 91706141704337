import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { GlobalContext } from '../GlobalContext';


const Logout: React.FC<{}> = (props) => {
    const { dispatch } = useContext(GlobalContext);
    dispatch({ type: 'CLEAR_CURRENT_USER' })

    return (
        <Redirect to={'/login'} />
    )
}

export default Logout
