import React, { useState, useMemo, useContext } from "react";
import { Form, Input, Button, PageHeader, Row, Col } from "antd";
import { useMutation } from "react-apollo";
import { CREATE_USER } from "CRUD/User.crud";
import { GlobalContext } from "../GlobalContext";
import { Redirect } from "react-router-dom";

const CreateAccount: React.FC<{}> = React.memo((props) => {
    const { dispatch } = useContext(GlobalContext);
    const [createUser] = useMutation(CREATE_USER);
    const [componentState, setComponentState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        createUserSuccess: false,
        isLoading: false,
    });

    const handleSubmit = (e: any) => {
        const credentials = {
            firstName: componentState.firstName,
            lastName: componentState.lastName,
            email: componentState.email,
            password: componentState.password,
        };

        setComponentState({ ...componentState, isLoading: true });
        createUser({
            variables: credentials,
        }).then((response) => {
            localStorage.setItem("auth-token", response.data.createUser.token);
            dispatch({
                type: "UPDATE_CURRENT_USER",
                payload: {
                    currentUser: response.data.createUser.user,
                },
            });
            setComponentState({
                password: "",
                email: "",
                firstName: "",
                lastName: "",
                isLoading: false,
                createUserSuccess: true,
            });
        });
    };

    const CreateUserForm = useMemo(
        () => (
            <>
                <PageHeader title={"Register"} />

                <Form onFinish={handleSubmit} layout={"vertical"}>
                    <Row gutter={24}>
                        <Col xs={24} md={{ span: 6, offset: 6 }}>
                            <Form.Item label="First Name">
                                <Input
                                    value={componentState.firstName}
                                    onChange={(e) =>
                                        setComponentState({ ...componentState, firstName: e.target.value })
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item label="Last Name">
                                <Input
                                    value={componentState.lastName}
                                    onChange={(e) => setComponentState({ ...componentState, lastName: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} md={{ span: 6, offset: 6 }}>
                            <Form.Item label="Email">
                                <Input
                                    value={componentState.email}
                                    onChange={(e) => setComponentState({ ...componentState, email: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item label="Password">
                                <Input
                                    type="password"
                                    value={componentState.password}
                                    onChange={(e) => setComponentState({ ...componentState, password: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} md={{ span: 6, offset: 6 }}>
                            <Button type={"primary"} htmlType="submit">
                                Register
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        ),
        [componentState]
    );

    const redirect = <Redirect to="/for-sale" />;

    if (componentState.createUserSuccess) return redirect;
    else return CreateUserForm;
});

export default CreateAccount;
