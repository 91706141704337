
import React, { useState, useContext, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Card, Row, Col, Button, PageHeader, Input, Skeleton } from 'antd';
import { GET_USER, UPDATE_USER, DELETE_USER } from '../CRUD/User.crud';
import { useParams, Link } from 'react-router-dom';
import Gravatar from 'react-gravatar'
import Meta from 'antd/lib/card/Meta';
import { GlobalContext } from './GlobalContext';

// import { UserContext } from 'components/GlobalContext.';

const UserView = () => {
    const { id } = useParams<{ id: string }>();
    const [updateUser] = useMutation(UPDATE_USER);
    const [componentState, setComponentState] = useState({ firstName: '', lastName: '', email: '' })
    const { state } = useContext(GlobalContext);

    const { loading, data, refetch } = useQuery(GET_USER, { variables: { nanoid: id } });
    // const [updateUser] = useMutation(UPDATE_USER);
    const [deleteUser] = useMutation(DELETE_USER);

    useEffect(() => {
        if (!data) return;
        setComponentState({
            ...data.user
        })
    }, [data]);

    useEffect(() => {
        refetch()
    }, [])

    if (loading) return <Skeleton active/>;
    // if (error) return <>{`error.message: ${error.message}`}</>
    if (!data?.user) return <>No user found</>;

    const { user } = data;

    const canEdit = state.currentUser.role === 'admin' || state.currentUser.nanoid === user.nanoid;

    return (<>
        <PageHeader title={'User View'} />
        <Row gutter={24}>

            <Col lg={6} md={12} xs={24} key={user.nanoid}>
                <>
                    <Card title={<Link to={`/user/${user.nanoid}`}>{user.firstName} {user.lastName}</Link>} extra={<a href="#" onClick={(e) => {
                        e.preventDefault();
                        deleteUser({ variables: { nanoid: user.nanoid } });
                    }}>x</a>} style={{ width: 300 }}>
                        {canEdit ? <>
                            First Name: <Input value={componentState.firstName} onChange={(e) => setComponentState({ ...componentState, firstName: e.target.value })} />
                            Last Name: <Input value={componentState.lastName} onChange={(e) => setComponentState({ ...componentState, lastName: e.target.value })} />
                            Email: <Input value={componentState.email} onChange={(e) => setComponentState({ ...componentState, email: e.target.value })} />
                            <Button onClick={() => { updateUser({ variables: { ...componentState, nanoid: user.nanoid } }) }} >Save</Button>
                        </>
                            : <Meta
                                avatar={<Gravatar email={user.email || ''} />}
                                title={`${user.firstName} ${user.lastName}`}
                                description={user.email}
                            />}

                    </Card>
                </>
            </Col>
        </Row>
    </>
    )
}

export default UserView
