import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, PageHeader } from 'antd'

import { useParams, Redirect } from 'react-router-dom';

interface IParams {
    token: string
}
const ForgotPassword: React.FC<{}> = () => {
    const params: IParams = useParams();
    const [form] = Form.useForm();

    const [componentState, setComponentState] = useState({
        email: '',
        password: '',
        token: params.token,
        isLoading: false,
        forgotSuccess: false,
    });

    const handleForgot = async () => {

        setComponentState({ ...componentState, isLoading: true })
        await fetch('/password/reset', {
            method: 'POST', headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }, body: JSON.stringify({ email: componentState.email, password: componentState.password, token: componentState.token })
        })
        setComponentState({ email: '', password: '', token: '', isLoading: false, forgotSuccess: true });
    }

    const handleChange = (e: any, field: string) => {
        setComponentState({ ...componentState, [field]: e.target.value });
    }

    const forgotForm = (
        <>
            <PageHeader title={'Change Password'} />
            <Row>
                <Col xs={24} lg={24} xl={{ span: 8, offset: 8 }}>

                    <Form form={form} onFinish={handleForgot} layout={'vertical'}>
                        {/* <Input onChange={(e) => handleChange(e, 'email')} /> */}
                        <Form.Item label="Email">
                            <Input value={componentState.email} onChange={(e) => handleChange(e, 'email')} />
                        </Form.Item>
                        <Form.Item label="New Password">
                            <Input type="password" value={componentState.password} onChange={(e) => handleChange(e, 'password')} />
                        </Form.Item>

                        <Button loading={componentState.isLoading} type="primary" htmlType="submit" >Change Password</Button>
                    </Form>
                </Col>
            </Row >
        </>
    )

    const redirect = <Redirect to="/login" />;

    if (componentState.forgotSuccess) return redirect;
    else return forgotForm;
}

export default ForgotPassword
