import { createContext, useReducer } from 'react';
import { IUser } from 'types';

interface IState {
    currentUser: Partial<IUser>
}
const localCurrentUser = localStorage.getItem('currentUser');
const initialState: IState = localCurrentUser ? { currentUser: JSON.parse(localCurrentUser) } : {
    currentUser: {}
};
export const GlobalContext = createContext<IState | any>(initialState);
interface IAction {
    type: string;
    payload: any;
}

function reducer(state: IState, action: IAction): IState {
    switch (action.type) {
        case 'UPDATE_CURRENT_USER':
            localStorage.setItem('currentUser', JSON.stringify({ ...state.currentUser, ...action.payload.currentUser }));
            return { ...state, currentUser: action.payload.currentUser };
        case 'CLEAR_CURRENT_USER':
            localStorage.removeItem('currentUser');
            localStorage.removeItem('auth-token');
            return { ...state, currentUser: {} };
        default:
            return state;
    }
}

export const GlobalProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <GlobalContext.Provider value={{ state, dispatch }}>{children}</GlobalContext.Provider>;
};
