import { createContext, useState } from "react";
import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";

interface IFilter {
    source: string;
    performance: string;
    sourceId: string;
    lienPosition: string;
    seller: string;
    minListPrice: number;
    maxListPrice: number;
    minInterestRate: number;
    maxInterestRate: number;
    minPaymentsRemaining: number;
    maxPaymentsRemaining: number;
    minOriginalAmortization: number;
    maxOriginalAmortization: number;
    minPrincipalAndInterestPayment: number;
    maxPrincipalAndInterestPayment: number;
    minUpb: number;
    maxUpb: number;
    judicial: boolean;
    nonJudicial: boolean;
    legalStatus: string;
    states: string[];
    propertyTypes: string[];
    noteTypes: string[];
    showPendingSales: boolean;
    archived: boolean;
    closedWon: boolean;
    closedLost: boolean;
    searchTerm: string;
}

const blankFilters: IFilter = {
    source: "",
    performance: "",
    sourceId: "",
    lienPosition: "",
    seller: "",
    minListPrice: 0,
    maxListPrice: 0,
    minInterestRate: 0,
    maxInterestRate: 0,
    minPaymentsRemaining: 0,
    maxPaymentsRemaining: 0,
    minOriginalAmortization: 0,
    maxOriginalAmortization: 0,
    minPrincipalAndInterestPayment: 0,
    maxPrincipalAndInterestPayment: 0,
    minUpb: 0,
    maxUpb: 0,
    judicial: false,
    nonJudicial: false,
    legalStatus: "",
    states: [],
    propertyTypes: [],
    noteTypes: [],
    showPendingSales: false,
    archived: false,
    closedWon: false,
    closedLost: false,
    searchTerm: "",
};

export type FilterContextType = {
    filters: IFilter;
    setFilters: (newFilters: IFilter) => void;
    clearFilters: () => void;
};

export const FilterContext = createContext<FilterContextType>({
    filters: blankFilters,
    setFilters: () => {},
    clearFilters: () => {},
});

const convertToQueryParam = (obj: { [x: string]: any }) => {
    return Object.entries(obj)
        .filter(([_, v]) => v)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value) || ""}`)
        .join("&");
};

const FilterContextProvider = ({ children }: any) => {
    const { params, path } = useRouteMatch();
    const history = useHistory();

    const qparams: any = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchqparams, prop: string) => searchqparams.get(prop),
    });

    const initialFilters = {
        ...blankFilters,
        source: qparams.source ?? blankFilters.source,
        performance: qparams.performance ?? blankFilters.performance,
        sourceId: qparams.sourceId ?? blankFilters.sourceId,
        lienPosition: qparams.lienPosition ?? blankFilters.lienPosition,
        seller: qparams.seller ?? blankFilters.seller,
        minListPrice: qparams.minListPrice !== "0" ? +qparams.minListPrice : blankFilters.minListPrice,
        maxListPrice: qparams.maxListPrice !== "0" ? +qparams.maxListPrice : blankFilters.maxListPrice,
        minInterestRate: qparams.minInterestRate !== "0" ? +qparams.minInterestRate : blankFilters.minInterestRate,
        maxInterestRate: qparams.maxInterestRate !== "0" ? +qparams.maxInterestRate : blankFilters.maxInterestRate,
        minPaymentsRemaining:
            qparams.minPaymentsRemaining !== "0" ? +qparams.minPaymentsRemaining : blankFilters.minPaymentsRemaining,
        maxPaymentsRemaining:
            qparams.maxPaymentsRemaining !== "0" ? +qparams.maxPaymentsRemaining : blankFilters.maxPaymentsRemaining,
        minOriginalAmortization:
            qparams.minOriginalAmortization !== "0"
                ? +qparams.minOriginalAmortization
                : blankFilters.minOriginalAmortization,
        maxOriginalAmortization:
            qparams.maxOriginalAmortization !== "0"
                ? +qparams.maxOriginalAmortization
                : blankFilters.maxOriginalAmortization,
        minPrincipalAndInterestPayment:
            qparams.minPrincipalAndInterestPayment !== "0"
                ? +qparams.minPrincipalAndInterestPayment
                : blankFilters.minPrincipalAndInterestPayment,
        maxPrincipalAndInterestPayment:
            qparams.maxPrincipalAndInterestPayment !== "0"
                ? +qparams.maxPrincipalAndInterestPayment
                : blankFilters.maxPrincipalAndInterestPayment,
        minUpb: qparams.minUpb !== "0" ? +qparams.minUpb : blankFilters.minUpb,
        maxUpb: qparams.maxUpb !== "0" ? +qparams.maxUpb : blankFilters.maxUpb,
        judicial: qparams.judicial === "true" ?? blankFilters.judicial,
        nonJudicial: qparams.nonJudicial === "true" ?? blankFilters.nonJudicial,
        legalStatus: qparams.legalStatus ?? blankFilters.legalStatus,
        states: qparams.states ? qparams.states.split(",") : blankFilters.states,
        propertyTypes: qparams.propertyTypes ? qparams.propertyTypes.split(",") : blankFilters.propertyTypes,
        noteTypes: qparams.noteTypes ? qparams.noteTypes.split(",") : blankFilters.noteTypes,
        showPendingSales: qparams.showPendingSales === "true" ?? blankFilters.showPendingSales,
        archived: qparams.archived === "true" ?? blankFilters.archived,
        closedWon: qparams.closedWon === "true" ?? blankFilters.closedWon,
        closedLost: qparams.closedLost === "true" ?? blankFilters.closedLost,
        searchTerm: qparams.searchTerm ?? blankFilters.searchTerm,
    };

    const setFilters = (updatedParams: IFilter) => {
        history.push({ search: convertToQueryParam(updatedParams), pathname: path });
        _setFilters({ ...params, ...updatedParams });
    };

    const [filters, _setFilters] = useState<IFilter>(initialFilters);

    const clearFilters = () => {
        setFilters(blankFilters);
    };

    return <FilterContext.Provider value={{ filters, setFilters, clearFilters }}>{children}</FilterContext.Provider>;
};

export default FilterContextProvider;
