import { Card, Row, Col, Space } from 'antd';
import Gravatar from 'react-gravatar'
import Meta from 'antd/lib/card/Meta';

import useFetch from 'use-http'


// import { UserContext } from 'components/GlobalContext.';
import { IWaitlist } from '../types/index';

const WaitlistList = () => {

    // const [updateUser] = useMutation(UPDATE_USER, { refetchQueries: mutationResult => [{ query: GET_USERS }] });
    const { loading, error, data = [] } = useFetch(`${process.env.REACT_APP_BASE_API_URL}/waitlist/show_all`, {}, [])
    // if (loading) return <>Loading...</>
    // if (error) return <>{`error.message: ${error.message}`}</>

    console.log('data', data)

    return (
        <Row gutter={24}>

            {!!data.length && data.map((record: IWaitlist) => {
                return (
                    <Col xs={24} md={24} lg={10} xxl={6} key={record.email}>
                        <Space>
                            <Card title={`${record.first_name} ${record.last_name}`} style={{ width: 300 }}>
                                <Meta
                                    avatar={<Gravatar email={record.email || ''} />}
                                    title={`${record.first_name} ${record.last_name}`}
                                    description={record.email}
                                />

                            </Card>
                        </Space>
                    </Col>)
            })}
        </Row>)
}

export default WaitlistList
