import fetchIntercept from 'fetch-intercept';
import jwtHelper from './jwt'


const unregister = fetchIntercept.register({
    request: function (url, config) {
        const currentJwtActive = !jwtHelper.isExpired(localStorage.getItem('auth-token')||'')

        // TODO: eventually handle refresh-token here
        if (!currentJwtActive && localStorage.getItem('auth-token')) {
            localStorage.clear();
            window.location.href="/logout";
            return[];
        }

        // Modify the url or config here
        return [url, config];
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        // Modify the reponse object
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});
export default unregister
