export const ordinal = (n: number) => {
    return n + (n > 0 ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : "");
};
export const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const percentage = (input: number) => {
    return (input * 100).toFixed(2) + "%";
};

export const camel2title = (camelCase: string) =>
    camelCase.replace(/([A-Z])/g, (match: string) => ` ${match}`).replace(/^./, (match: string) => match.toUpperCase());

export const underscore2camel = (str: string) =>
    (str &&
        str.replace(/_([a-z])/g, function (g) {
            return g[1].toUpperCase();
        })) ||
    "";

export const performanceToAbbreviation = (performance: string) => {
    if (performance.toLowerCase().includes("non-")) return "NPL";
    if (performance.toLowerCase().includes("performing")) return "PL";
    return performance;
};

export const loanTypeToAbbreviation = (type: string) => {
    if (type.toLowerCase().includes("contract for deed")) return "CFD";
    if (type.toLowerCase().includes("mortgage")) return "Mort.";
    if (type.toLowerCase().includes("deed of trust")) return "DOT";
    return type;
};

export default function titleize(str: string) {
    if (typeof str !== "string") {
        throw new TypeError("Expected a string");
    }

    return str.toLowerCase().replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
}

export function stateNameToAbbreviation(name: string) {
    let states: any = {
        arizona: "AZ",
        alabama: "AL",
        alaska: "AK",
        arkansas: "AR",
        california: "CA",
        colorado: "CO",
        connecticut: "CT",
        "district of columbia": "DC",
        delaware: "DE",
        florida: "FL",
        georgia: "GA",
        hawaii: "HI",
        idaho: "ID",
        illinois: "IL",
        indiana: "IN",
        iowa: "IA",
        kansas: "KS",
        kentucky: "KY",
        louisiana: "LA",
        maine: "ME",
        maryland: "MD",
        massachusetts: "MA",
        michigan: "MI",
        minnesota: "MN",
        mississippi: "MS",
        missouri: "MO",
        montana: "MT",
        nebraska: "NE",
        nevada: "NV",
        "new hampshire": "NH",
        "new jersey": "NJ",
        "new mexico": "NM",
        "new york": "NY",
        "north carolina": "NC",
        "north dakota": "ND",
        ohio: "OH",
        oklahoma: "OK",
        oregon: "OR",
        pennsylvania: "PA",
        "rhode island": "RI",
        "south carolina": "SC",
        "south dakota": "SD",
        tennessee: "TN",
        texas: "TX",
        utah: "UT",
        vermont: "VT",
        virginia: "VA",
        washington: "WA",
        "west virginia": "WV",
        wisconsin: "WI",
        wyoming: "WY",
        "american samoa": "AS",
        guam: "GU",
        "northern mariana islands": "MP",
        "puerto rico": "PR",
        "us virgin islands": "VI",
        "us minor outlying islands": "UM",
    };

    let a = name
        .trim()
        .replace(/[^\w ]/g, "")
        .toLowerCase(); //Trim, remove all non-word characters with the exception of spaces, and convert to lowercase
    if (states[a] !== null) {
        return states[a];
    }

    return null;
}
