import { Form, Input, Radio, Select, Switch } from "antd";
import { GET_FILTERS } from "CRUD/FilterOptions.Note";
import { useQuery } from "react-apollo";
import { Option } from "antd/lib/mentions";
import { useContext } from "react";
import { FilterContext } from "./FiltersContext";

const Filters = () => {
    const { filters, setFilters } = useContext(FilterContext);
    const { data: filtersData } = useQuery(GET_FILTERS);

    if (!filtersData) return null;
    if (!filters) return null;

    return (
        <Form layout="vertical">
            <Form.Item label="Performance">
                <Radio.Group
                    options={[
                        { label: "All", value: "" },
                        { label: "Performing", value: "Performing" },
                        { label: "Non-Performing", value: "Non-Performing" },
                        { label: "REO", value: "REO" },
                    ]}
                    onChange={(e) => {
                        setFilters({ ...filters, performance: e.target.value });
                    }}
                    value={filters.performance || ""}
                    // optionType="button"
                />
            </Form.Item>
            <Form.Item label="Position">
                <Radio.Group
                    options={[
                        { label: "All", value: "" },
                        { label: "1st", value: "1" },
                        { label: "2nd", value: "2" },
                    ]}
                    onChange={(e) => {
                        setFilters({ ...filters, lienPosition: e.target.value });
                    }}
                    value={filters.lienPosition || ""}
                    // optionType="button"
                />
            </Form.Item>
            <Form.Item label="List Price">
                <Input
                    type="number"
                    placeholder={"Min"}
                    value={filters.minListPrice || ""}
                    onChange={(e: any) => setFilters({ ...filters, minListPrice: +e.target.value })}
                />
                <Input
                    type="number"
                    placeholder={"Max"}
                    value={filters.maxListPrice || ""}
                    onChange={(e: any) => setFilters({ ...filters, maxListPrice: +e.target.value })}
                />
            </Form.Item>

            <Form.Item label="Borrower Interest Rate">
                <Input
                    type="number"
                    placeholder={"Min"}
                    value={filters.minInterestRate || ""}
                    onChange={(e: any) => setFilters({ ...filters, minInterestRate: +e.target.value })}
                />
                <Input
                    type="number"
                    placeholder={"Max"}
                    value={filters.maxInterestRate || ""}
                    onChange={(e: any) => setFilters({ ...filters, maxInterestRate: +e.target.value })}
                />
            </Form.Item>

            <Form.Item label="Principal & Interest Payment">
                <Input
                    type="number"
                    placeholder={"Min"}
                    value={filters.minPrincipalAndInterestPayment || ""}
                    onChange={(e: any) => setFilters({ ...filters, minPrincipalAndInterestPayment: +e.target.value })}
                />
                <Input
                    type="number"
                    placeholder={"Max"}
                    value={filters.maxPrincipalAndInterestPayment || ""}
                    onChange={(e: any) => setFilters({ ...filters, maxPrincipalAndInterestPayment: +e.target.value })}
                />
            </Form.Item>

            <Form.Item label="UPB">
                <Input
                    type="number"
                    placeholder={"Min"}
                    value={filters.minUpb || ""}
                    onChange={(e: any) => setFilters({ ...filters, minUpb: +e.target.value })}
                />
                <Input
                    type="number"
                    placeholder={"Max"}
                    value={filters.maxUpb || ""}
                    onChange={(e: any) => setFilters({ ...filters, maxUpb: +e.target.value })}
                />
            </Form.Item>

            <Form.Item label="Payments Remaining">
                <Input
                    type="number"
                    placeholder={"Min"}
                    value={filters.minPaymentsRemaining || ""}
                    onChange={(e: any) => setFilters({ ...filters, minPaymentsRemaining: +e.target.value })}
                />
                <Input
                    type="number"
                    placeholder={"Max"}
                    value={filters.maxPaymentsRemaining || ""}
                    onChange={(e: any) => setFilters({ ...filters, maxPaymentsRemaining: +e.target.value })}
                />
            </Form.Item>

            <Form.Item label="Original Amortization">
                <Input
                    type="number"
                    placeholder={"Min"}
                    value={filters.minOriginalAmortization || ""}
                    onChange={(e: any) => setFilters({ ...filters, minOriginalAmortization: +e.target.value })}
                />
                <Input
                    type="number"
                    placeholder={"Max"}
                    value={filters.maxOriginalAmortization || ""}
                    onChange={(e: any) => setFilters({ ...filters, maxOriginalAmortization: +e.target.value })}
                />
            </Form.Item>

            <Form.Item label="State">
                <Select
                    mode="multiple"
                    allowClear
                    // style={{ width: '100%' }}
                    placeholder="Select States"
                    value={filters.states || []}
                    onChange={(states: string[]) => setFilters({ ...filters, states })}
                >
                    {filtersData?.filters?.states.map((state: string) => {
                        return (
                            <Option value={state} key={state}>
                                {state}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>

            <Form.Item label="Property Type">
                <Select
                    mode="multiple"
                    allowClear
                    // style={{ width: '100%' }}
                    placeholder="Select Property Type"
                    value={filters.propertyTypes || []}
                    onChange={(propertyTypes: string[]) => setFilters({ ...filters, propertyTypes })}
                >
                    {filtersData?.filters?.propertyTypes.map((types: string) => {
                        return (
                            <Option value={types} key={types}>
                                {types}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>

            <Form.Item label="Note Type">
                <Select
                    mode="multiple"
                    allowClear
                    // style={{ width: '100%' }}
                    placeholder="Select Note Type"
                    value={filters.noteTypes || []}
                    onChange={(noteTypes: string[]) => setFilters({ ...filters, noteTypes })}
                >
                    {filtersData?.filters?.noteTypes.map((noteType: string) => {
                        return (
                            <Option value={noteType} key={noteType}>
                                {noteType}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>

            <Form.Item label="State Classification">
                <Switch
                    checkedChildren={"Judicial"}
                    unCheckedChildren={"Judicial"}
                    checked={filters.judicial}
                    onChange={(checked: boolean) => setFilters({ ...filters, judicial: checked })}
                />
                <Switch
                    checkedChildren={"Non-Judicial"}
                    unCheckedChildren={"Non-Judicial"}
                    checked={filters.nonJudicial}
                    onChange={(checked: boolean) => setFilters({ ...filters, nonJudicial: checked })}
                />
            </Form.Item>

            <Form.Item label="Seller">
                <Select
                    allowClear
                    value={filters.seller}
                    onChange={(val: string) => setFilters({ ...filters, seller: val })}
                >
                    {filtersData?.filters?.sellers.map((seller: string) => (
                        <Select.Option key={seller} value={seller}>
                            {seller}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="Legal Status">
                <Select
                    allowClear
                    value={filters.legalStatus}
                    // onClear={() => setFilters({ ...filters, legalStatus: "" })}
                    onChange={(val: string) => setFilters({ ...filters, legalStatus: val })}
                >
                    {filtersData?.filters?.legalStatus.map((legalStatus: string) => (
                        <Select.Option key={legalStatus} value={legalStatus}>
                            {legalStatus}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="Source">
                <Radio.Group
                    options={[
                        { label: "All", value: "" },
                        { label: "Paperstac", value: "Paperstac" },
                        { label: "Notes Direct", value: "Notes Direct" },
                    ]}
                    onChange={(e) => {
                        setFilters({ ...filters, source: e.target.value });
                    }}
                    value={filters.source}
                    // optionType="button"
                />
            </Form.Item>

            <Form.Item label="Show Pending Sales">
                <Switch
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checked={filters.showPendingSales}
                    onChange={(checked: boolean) => setFilters({ ...filters, showPendingSales: checked })}
                />
            </Form.Item>
            <Form.Item label="Show Only Archived">
                <Switch
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checked={filters.archived}
                    onChange={(checked: boolean) => setFilters({ ...filters, archived: checked })}
                />
            </Form.Item>

            <Form.Item label="Show Closed Won">
                <Switch
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checked={filters.closedWon}
                    onChange={(checked: boolean) => setFilters({ ...filters, closedWon: checked })}
                />
            </Form.Item>

            <Form.Item label="Show Closed Lost">
                <Switch
                    checkedChildren={"Yes"}
                    unCheckedChildren={"No"}
                    checked={filters.closedLost}
                    onChange={(checked: boolean) => setFilters({ ...filters, closedLost: checked })}
                />
            </Form.Item>
        </Form>
    );
};

export default Filters;
