import gql from 'graphql-tag'

export const GET_PIPELINE_FILTERED = gql`
    query GetPipelineFiltered(
        $sourceId: String!,
        $source: String!,
        $performance: String!,
        $lienPosition: String!,
        $seller: String!,
        $maxListPrice: Float!,
        $minListPrice: Float!,
        $minInterestRate: Float!,
        $maxInterestRate: Float!,
        $minPaymentsRemaining: Float!,
        $maxPaymentsRemaining: Float!,
        $minOriginalAmortization: Float!,
        $maxOriginalAmortization: Float!,
        $minPrincipalAndInterestPayment: Float!,
        $maxPrincipalAndInterestPayment: Float!,
        $minUpb: Float!,
        $maxUpb: Float!,
        $judicial: Boolean!,
        $nonJudicial: Boolean!,
        $states: [String!],
        $propertyTypes: [String!],
        $noteTypes: [String!]
        $legalStatus: String!,
        $showPendingSales: Boolean!,
        $archived: Boolean!,
        $closedWon: Boolean!,
        $closedLost: Boolean!,
        $searchTerm: String! )
        {
        userPipeline(
            filter:{
                sourceId: $sourceId,
                source:$source,
                performance: $performance,
                lienPosition: $lienPosition,
                seller: $seller,
                maxListPrice: $maxListPrice,
                minListPrice: $minListPrice,
                minInterestRate: $minInterestRate,
                maxInterestRate: $maxInterestRate,
                minPaymentsRemaining: $minPaymentsRemaining,
                maxPaymentsRemaining: $maxPaymentsRemaining,
                minOriginalAmortization: $minOriginalAmortization,
                maxOriginalAmortization: $maxOriginalAmortization,
                minPrincipalAndInterestPayment: $minPrincipalAndInterestPayment,
                maxPrincipalAndInterestPayment: $maxPrincipalAndInterestPayment,
                minUpb: $minUpb,
                maxUpb: $maxUpb,
                judicial: $judicial,
                nonJudicial: $nonJudicial,
                states: $states,
                propertyTypes: $propertyTypes,
                noteTypes: $noteTypes,
                legalStatus: $legalStatus,
                showPendingSales: $showPendingSales,
                archived: $archived,
                closedWon: $closedWon,
                closedLost: $closedLost,
                searchTerm: $searchTerm,
            }) {
            nodes{
                id
                nanoid
                dueDiligencePhase
                archived
                closedWon
                closedLost
                note {
                    streetAddress,
                    city,
                    state,
                    zip,
                    lienPosition
                    listPrice
                    upb
                    performance
                    noteType
                    propertyValueSource
                    source
                    seller
                }
            }
        }
    }
`

// TODO: make this not a thing. graphql is finicky with returing the total count of filtered objects
export const GET_PIPELINE_FILTERED_COUNT = gql`
query GetPipelineFiltered(
    $sourceId: String!,
    $source: String!,
    $performance: String!,
    $lienPosition: String!,
    $seller: String!,
    $maxListPrice: Float!,
    $minListPrice: Float!,
    $minInterestRate: Float!,
    $maxInterestRate: Float!,
    $minPaymentsRemaining: Float!,
    $maxPaymentsRemaining: Float!,
    $minOriginalAmortization: Float!,
    $maxOriginalAmortization: Float!,
    $minPrincipalAndInterestPayment: Float!,
    $maxPrincipalAndInterestPayment: Float!,
    $minUpb: Float!,
    $maxUpb: Float!,
    $judicial: Boolean!,
    $nonJudicial: Boolean!,
    $states: [String!],
    $propertyTypes: [String!],
    $noteTypes: [String!]
    $legalStatus: String!,
    $showPendingSales: Boolean!,
    $archived: Boolean!,
    $closedWon: Boolean!,
    $closedLost: Boolean!,
    $dueDiligencePhase: [String!]) {
    userPipeline(
        filter:{
            sourceId: $sourceId,
            source:$source,
            performance: $performance,
            lienPosition: $lienPosition,
            seller: $seller,
            maxListPrice: $maxListPrice,
            minListPrice: $minListPrice,
            minInterestRate: $minInterestRate,
            maxInterestRate: $maxInterestRate,
            minPaymentsRemaining: $minPaymentsRemaining,
            maxPaymentsRemaining: $maxPaymentsRemaining,
            minOriginalAmortization: $minOriginalAmortization,
            maxOriginalAmortization: $maxOriginalAmortization,
            minPrincipalAndInterestPayment: $minPrincipalAndInterestPayment,
            maxPrincipalAndInterestPayment: $maxPrincipalAndInterestPayment,
            minUpb: $minUpb,
            maxUpb: $maxUpb,
            judicial: $judicial,
            nonJudicial: $nonJudicial,
            states: $states,
            propertyTypes: $propertyTypes,
            noteTypes: $noteTypes,
            legalStatus: $legalStatus,
            showPendingSales: $showPendingSales,
            archived: $archived,
            closedWon: $closedWon,
            closedLost: $closedLost,
            dueDiligencePhase: $dueDiligencePhase,
        }) {
        nodes{
                noLiens
            }
        }
    }
`

export const GET_PIPELINE_DEAL = gql`
    query GetPipelineDeal($nanoid: String!){
        pipeline(nanoid: $nanoid) {
            id
            nanoid
            dueDiligencePhase
            archived
            closedWon
            closedLost
            comments {
                id
                text
                user {
                    firstName
                    lastName
                    email
                }
                createdAt
            }
            note {
                loanId
                servicer
                legalStatus
                lienPosition
                streetAddress
                streetAddress2
                city
                state
                zip
                county
                propertyType
                propertyTaxId
                occupancyStatus
                upb
                interestRate
                escrowImpounds
                accruedLateCharges
                principalAndInterestPayment
                totalMonthlyLoanPayment
                totalPayoff
                originationDate
                lastPaymentReceivedDate
                nextPaymentDate
                maturityDate
                performance
                listingType
                listDate
                seller
                availability
                negotiationType
                noteType
                propertyValueSource
                propertyValueSourceType
                propertyValueSourceDate
                paymentsRemaining
                hardestHitFundState
                judicial
                nonJudicial
                originalAmortization
                interestOnlyLoan
                sourceLastUpdated
                sourceId
                nanoid
                originalBalance
                listPrice
                source
                sourceUrl
                googleUrl
                publicRecord {
                    estatedResponse
                }
                noOfBids
                currentBid
            }
        }
    }
`

export const UPDATE_NOTE = gql`
   mutation UpdateUser($nanoid: String!, $email: String!,  $firstName: String!,  $lastName: String!){
       updateUser(input: {nanoid: $nanoid, email: $email, firstName: $firstName, lastName: $lastName}) {
           user {
               nanoid
               firstName,
               lastName,
               email

           }
           errors
       }
   }
`

export const UPLOAD_CSV = gql`
  mutation CreateUser($firstName: String!,$lastName: String!, $email: String!, $password: String!) {
    createUser(input: {firstName: $firstName,lastName: $lastName, email: $email, password: $password})
    {token user {firstName, lastName, email}}
  }`


  export const UPDATE_PIPELINE = gql`
    mutation UpdatePipeline($nanoid: String!, $dueDiligencePhase: String!, $closedWon: Boolean!, $closedLost: Boolean!, $archived: Boolean!) {
        updatePipeline(input: {nanoid: $nanoid, dueDiligencePhase: $dueDiligencePhase, closedWon: $closedWon, closedLost: $closedLost, archived: $archived}) {
            pipeline {
                id
                nanoid
                dueDiligencePhase
                archived
                closedWon
                closedLost
                note {
                    nanoid
                    streetAddress,
                    city,
                    state,
                    zip,
                    lienPosition
                    listPrice
                    upb
                    propertyValueSource
                    performance
                    noteType
                    paymentsRemaining
                    principalAndInterestPayment
                    propertyValueSource
                    availability
                    source
                    seller
                    sourceUrl
                    googleUrl
                }
            }
            errors
        }
    }
`

export const CREATE_COMMENT = gql`
    mutation CreateComment($pipelineNanoid: String!, $userNanoid: String!, $text: String!){
        createComment(input: {pipelineNanoid: $pipelineNanoid, userNanoid: $userNanoid, text: $text}) {
            comment {
                id
                text
            }
            errors
        }
    }
`
export const DELETE_COMMENT = gql`
   mutation DeleteComment($id: ID!){
       deleteComment(input: {id: $id}) {
           errors
       }
   }
`
