import UsersList from './components/UsersList';
import UserView from './components/UserView';
import Login from './components/Auth/Login.component';
import Logout from './components/Auth/Logout.component';
import CreateAccount from './components/Auth/CreateAccount.component';
import { Route, Switch, Redirect } from 'react-router-dom';
import UploadTapeForm from './components/UploadTape';
import PipelineDeals from './components/PipelineDeals';
import PipelineDealViewFull from './components/PipelineDealViewFull';
import ForgotPassword from './components/Auth/ForgotPassword.component';
import ResetPassword from './components/Auth/ResetPassword.component';
import VerifySource from './components/VerifySource.component';
import WaitlistList from './components/WaitlistList';
import FilterContextProvider from 'components/FiltersContext';
// import { FiltersProvider } from 'components/FiltersContext';

export const AppRoutes = () => {
  return (
    <Switch>
      <Route path="/users">
        <UsersList />
      </Route>
      <Route path="/user/:id">
        <UserView />
      </Route>
      <Route exact path="/deals">
        <FilterContextProvider>
          <PipelineDeals />
        </FilterContextProvider>
      </Route>
      <Route exact path="/for-sale/:id">
        <PipelineDealViewFull />
      </Route>
      {/* <Route path="/note/:id">
                <NoteView />
            </Route> */}
      <Route path="/login">
        <FilterContextProvider>
          <Login />
        </FilterContextProvider>
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/create-account">
        <CreateAccount />
      </Route>
      <Route path="/upload-csv">
        <UploadTapeForm />
      </Route>
      <Route path="/password/forgot">
        <ForgotPassword />
      </Route>
      <Route path="/password/reset/:token">
        <ResetPassword />
      </Route>
      <Route path="/verify-sources">
        <VerifySource />
      </Route>
      <Route path="/waitlist">
        <WaitlistList />
      </Route>
      <Route path="/">
        <Redirect to={'/deals'} />
      </Route>
    </Switch>

  )
}
