import React, { useState } from "react";
import { Form, Input, Button, Row, Col, PageHeader } from "antd";
// import { GlobalContext } from 'components/GlobalContext';

const ForgotPassword: React.FC<{}> = () => {
    const [form] = Form.useForm();

    const [componentState, setComponentState] = useState({
        email: "",
        isLoading: false,
        forgotSuccess: false,
    });
    // const { state, dispatch } = useContext(GlobalContext);

    const handleForgot = async () => {
        // setComponentState({ ...componentState, isLoading: true })
        await fetch(process.env.REACT_APP_BASE_API_URL + "/password/forgot", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({ email: componentState.email }),
        });
        setComponentState({ email: "", isLoading: false, forgotSuccess: true });
    };

    const handleChange = (e: any, field: string) => {
        setComponentState({ ...componentState, [field]: e.target.value });
    };

    const forgotForm = (
        <>
            <PageHeader title={"Forgot Password"} />
            <Row>
                <Col xs={24} lg={24} xl={{ span: 8, offset: 8 }}>
                    <Form form={form} onFinish={handleForgot} layout={"vertical"}>
                        {/* <Input onChange={(e) => handleChange(e, 'email')} /> */}
                        <Form.Item label="Email">
                            <Input value={componentState.email} onChange={(e) => handleChange(e, "email")} />
                        </Form.Item>

                        <Button loading={componentState.isLoading} type="primary" htmlType="submit">
                            Forgot Password
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    );

    // const redirect = <Redirect to="/login" />;

    if (componentState.forgotSuccess) return <>Check your email</>;
    else return forgotForm;
};

export default ForgotPassword;
