import React, { useState, useEffect, useRef } from 'react';
import Finance from 'tvm-financejs'
import { NPER } from 'formulajs';
import { Row, Col, Button, Input, Form, Tooltip, Slider, Statistic } from 'antd';
import { INote } from 'types';
import { QuestionCircleOutlined, UndoOutlined } from '@ant-design/icons';

let finance = new Finance();

interface ICalculator {
    pv: number,
    fv: number;
    pmt: number;
    rate: number;
    nper: number;
    acquisition: number;
    servicerFee: number
    netRate: number;
}

const TvmCalculator: React.FC<{ note: INote }> = (props: { note: INote }) => {
    const [form] = Form.useForm();
    const isMounted = useRef(false);
    const [reset, setReset] = useState<boolean>(false)
    const initialCalaculator: ICalculator = {
        pv: (-1 * (props.note.listPrice || 0)),
        fv: props.note.interestOnlyLoan ? (props.note.upb || 0): 0,
        pmt: props.note.principalAndInterestPayment || 0,
        rate: +(finance.RATE(props.note.paymentsRemaining || 0, props.note.principalAndInterestPayment || 0, -1 * (props.note.listPrice || 0), 0, 1, .15) * 12 * 100).toFixed(2),
        nper: props.note.paymentsRemaining || 0,
        acquisition: 900,
        servicerFee: 18.5,
        netRate: +(finance.RATE(props.note.paymentsRemaining || 0, (props.note.principalAndInterestPayment || 0) - 18.5, -1 * (props.note.listPrice || 0) - 900, 0, 1, .15) * 12 * 100).toFixed(2),
    }

    const [calculator, setCalculator] = useState<ICalculator>(initialCalaculator)
    const [triggerCalculator, setTriggerCalculator] = useState({
        pv: true,
        fv: true,
        pmt: true,
        rate: true,
        nper: true
    })

    useEffect(() => {
        if (!isMounted.current) return; // dont run on init load
        const newRate: number = finance.RATE(calculator.nper, calculator.pmt, calculator.pv, calculator.fv, 1, .15);
        const newNetRate: number = finance.RATE(calculator.nper, (calculator.pmt - calculator.servicerFee), (calculator.pv - calculator.acquisition), calculator.fv, 1, .15);
        setCalculator({ ...calculator, rate: +(newRate * 12 * 100).toFixed(2), netRate: +(newNetRate * 12 * 100).toFixed(2) })
    }, [triggerCalculator.rate])


    useEffect(() => {
        if (!isMounted.current) return; // dont run on init load
        const newPv = finance.PV(calculator.rate / 100 / 12, calculator.nper, calculator.pmt, calculator.fv, 1);
        const newNetRate: number = finance.RATE(calculator.nper, (calculator.pmt - calculator.servicerFee), (newPv - calculator.acquisition), calculator.fv, 1, .15);
        setCalculator({ ...calculator, pv: +newPv.toFixed(2), netRate: +(newNetRate * 12 * 100).toFixed(2) });
    }, [triggerCalculator.pv])

    useEffect(() => {
        if (!isMounted.current) return; // dont run on init load
        const newFv = finance.FV(calculator.rate / 100 / 12, calculator.nper, calculator.pmt, calculator.pv);
        setCalculator({ ...calculator, fv: newFv.toFixed(2) });
    }, [triggerCalculator.fv])

    useEffect(() => {
        if (!isMounted.current) return; // dont run on init load
        const newNper = NPER(calculator.rate / 100 / 12, calculator.pmt, calculator.pv, calculator.fv, 1)
        setCalculator({ ...calculator, nper: newNper.toFixed(2) })
    }, [triggerCalculator.nper])

    useEffect(() => {
        if (!isMounted.current) return; // dont run on init load
        const newPmt = finance.PMT(calculator.rate / 100 / 12, calculator.nper, calculator.pv, calculator.fv, 1);
        setCalculator({ ...calculator, pmt: newPmt.toFixed(2) })
    }, [triggerCalculator.pmt])

    useEffect(() => {
        if (!isMounted.current) return; // dont run on init load
        setCalculator(initialCalaculator)
    }, [reset]);

    useEffect(() => {
        isMounted.current = true
    }, [])

    const note = props.note;
    const layout = {
        labelCol: {
            span: 10,
        },
        wrapperCol: {
            span: 14,
        },
    };
    return (<>
        <Form {...layout} form={form}>
            <Row gutter={24} style={{ paddingBottom: '15px' }}>
                <Col span={18}></Col>
                <Col span={6}>
                    <Button onClick={() => setReset(!reset)}><UndoOutlined /> Reset</Button>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={18}>
                    <Form.Item label="Present Value" initialValue={note.listPrice}>
                        <Input type="number" value={calculator.pv} onChange={(e) => setCalculator({ ...calculator, pv: +e.target.value })} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Button onClick={() => setTriggerCalculator({ ...triggerCalculator, pv: !triggerCalculator.pv })}>PV</Button> <UndoOutlined onClick={() => setCalculator({ ...calculator, pv: -1 * (note.listPrice || 0) })} />
                </Col>
            </Row>
            <Row gutter={24}>

                <Col
                    span={18}>
                    <Form.Item label="ITB">
                        <Slider
                            onChange={(e: number) => {
                                setCalculator({ ...calculator, pv: -e / 100 * (note.upb || 0) });
                                setTriggerCalculator({ ...triggerCalculator, rate: !triggerCalculator.rate });
                            }}
                            marks={{
                                0: '0%',
                                [(note.listPrice || 0) / (note.upb || 0) * 100]: `${((note.listPrice || 0) / (note.upb || 0) * 100).toFixed(1)}%`,
                                100: '100%',
                            }} value={(-1 * calculator.pv || 0) / (note.upb || 0) * 100} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col
                    span={18}>
                    <Form.Item label="ITV">
                        <Slider
                            onChange={(e: number) => {
                                setCalculator({ ...calculator, pv: -e / 100 * (note.propertyValueSource || 0) });
                                setTriggerCalculator({ ...triggerCalculator, rate: !triggerCalculator.rate });
                            }}
                            marks={{
                                0: '0%',
                                [(note.listPrice || 0) / (note.propertyValueSource || 0) * 100]: `${((note.listPrice || 0) / (note.propertyValueSource || 0) * 100).toFixed(1)}%`,
                                100: '100%',
                            }} value={(-1 * calculator.pv || 0) / (note.propertyValueSource || 0) * 100} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={7}>
                </Col>
                {/* <Col span={13}>
                    <Text code>List for ${note.listPrice}</Text>
                </Col> */}
            </Row>
            <Row gutter={24}>
                <Col span={18}>
                    <Form.Item label="Future Value">
                        <Input type="number" value={calculator.fv} onChange={(e) => setCalculator({ ...calculator, fv: +e.target.value })} />

                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Button onClick={() => setTriggerCalculator({ ...triggerCalculator, fv: !triggerCalculator.fv })}>FV</Button> <UndoOutlined onClick={() => setCalculator({ ...calculator, fv: 0 })} />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={18}>
                    <Form.Item label="Payment">
                        <Input
                            type="number"
                            value={calculator.pmt}
                            onChange={(e) => setCalculator({ ...calculator, pmt: +e.target.value })}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Button onClick={() => setTriggerCalculator({ ...triggerCalculator, pmt: !triggerCalculator.pmt })}>PMT</Button> <UndoOutlined onClick={() => setCalculator({ ...calculator, pmt: (note.principalAndInterestPayment || 0) })} />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={18}>
                    <Form.Item label="Interest Rate">
                        <Input
                            type="number"
                            value={calculator.rate}
                            onChange={(e) => {
                                setCalculator({ ...calculator, rate: +e.target.value });
                                setTriggerCalculator({ ...triggerCalculator, pv: !triggerCalculator.pv })
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Button onClick={() => setTriggerCalculator({ ...triggerCalculator, rate: !triggerCalculator.rate })}>Rate</Button>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={18}>
                    <Form.Item label="Periods">
                        <Input
                            type="number"
                            value={calculator.nper}
                            onChange={(e) => setCalculator({ ...calculator, nper: +e.target.value })}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Button
                        onClick={() => setTriggerCalculator({ ...triggerCalculator, nper: !triggerCalculator.nper })}>
                        NPER
                    </Button> <UndoOutlined onClick={() => setCalculator({ ...calculator, nper: (note.paymentsRemaining || 0) })} />
                </Col>
            </Row>
            <Row gutter={24}>


                <Col
                    span={18}>
                    <Form.Item label="Percentage of periods">
                        <Slider
                            onChange={(e: number) => {
                                setCalculator({ ...calculator, nper: e / 100 * (note.paymentsRemaining || 0) });
                                setTriggerCalculator({ ...triggerCalculator, rate: !triggerCalculator.rate });
                            }}
                            marks={{
                                0: '0%',
                                100: '100%',
                            }} value={calculator.nper / (note.paymentsRemaining || 0) * 100} />
                    </Form.Item>
                </Col>

            </Row>
            <Row gutter={24}>
                <Col span={18}>
                    <Form.Item label="Aquisition costs">
                        <Input
                            type="number"
                            value={calculator.acquisition}
                            onChange={(e) => {
                                setCalculator({ ...calculator, acquisition: +e.target.value });
                                setTriggerCalculator({ ...triggerCalculator, rate: !triggerCalculator.rate });
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}></Col>
            </Row>
            <Row gutter={24}>
                <Col span={18}>
                    <Form.Item label="Monthly Servicer Fee">
                        <Input
                            type="number"
                            value={calculator.servicerFee}
                            onChange={(e) => {
                                setCalculator({ ...calculator, servicerFee: +e.target.value });
                                setTriggerCalculator({ ...triggerCalculator, rate: !triggerCalculator.rate });
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}></Col>
            </Row>
            <Row gutter={24}>
                <Col xxl={8} lg={24}>
                <Statistic title="Total Investment " value={-1 * calculator.pv + calculator.acquisition} prefix={'$'} precision={2}/>
                </Col>
                <Col xxl={8} lg={24}>
                <Statistic title={<>Net YTM <Tooltip title="Yield to maturity after servicing fees with acquistion and due diligence costs factored into the purchase price" ><QuestionCircleOutlined /></Tooltip></>} value={calculator.netRate} suffix={'%'} precision={2} />
                </Col>
                <Col xxl={8} lg={24}>
                <Statistic title="Net Pmt" value={calculator.pmt-calculator.servicerFee} prefix={'$'} precision={2}/>
                </Col>
                <Col xxl={8} lg={24}>
                <Statistic title="Net Profit" value={((calculator.pmt-calculator.servicerFee)*calculator.nper) - (calculator.acquisition + -1* calculator.pv - calculator.fv)} prefix={'$'} precision={2}/>
                </Col>
                <Col xxl={8} lg={24}>
                <Statistic title="Net Equity Multiple" value={(((calculator.pmt-calculator.servicerFee)*calculator.nper + calculator.fv) / (-1* calculator.pv))} suffix={'x'} precision={2}/>
                </Col>
            </Row>
        </Form>
    </>
    )
}

export default TvmCalculator
