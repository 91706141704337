import React, { useState } from "react";
import { Row, Col, Skeleton, Card, Tag, Statistic, Badge, Menu, Dropdown, Button } from "antd";
import { Link } from "react-router-dom";

// import { UserContext } from 'components/GlobalContext.';
import { INote } from "types";
import titleize, {
    loanTypeToAbbreviation,
    percentage,
    performanceToAbbreviation,
    stateNameToAbbreviation,
} from "helpers/utils";
import { formatter } from "helpers/utils";
import { ordinal } from "helpers/utils";
import { IPipeline } from "../types/index";
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineClose, AiOutlineUsb, AiOutlineCheck } from "react-icons/ai";
import { useMutation } from "react-apollo";
import { UPDATE_PIPELINE } from "CRUD/Note.crud";
import { FaEllipsisV } from "react-icons/fa";
const PipelineDealItem: React.FC<{ paramPipeline?: IPipeline }> = (props: { paramPipeline?: IPipeline }) => {
    const [updatePipeline] = useMutation(UPDATE_PIPELINE);
    const [isHover, setIsHover] = useState(false);

    const { loading, error, data } = { loading: false, error: false, data: { pipeline: props.paramPipeline } };

    if (loading) return <Skeleton active />;
    if (error) return <>Oh noes! We've encountered an error. Please try again later</>;
    if (!data?.pipeline) return <>No note found</>;

    const note: Partial<INote> = data.pipeline.note;
    const pipelineRecord: Partial<IPipeline> = data.pipeline;

    const phases = ["inbox", "interested", "negotiating", "offer_accepted"];

    const itbColor = (percent: number, performance?: INote["performance"]) => {
        if (performance === "Non-Performing") {
            if (percent <= 0.6) return "green";
            if (percent <= 0.75) return "yellow";
        } else {
            if (percent <= 0.75) return "green";
            if (percent <= 0.85) return "yellow";
        }
        return "red";
    };
    const itvColor = (percent: number, performance?: INote["performance"]) => {
        if (percent <= 0.55) return "green";
        if (percent <= 0.7) return "yellow";
        return "red";
    };
    const buttonNavigatorStyles = {
        backgroundColor: "",
        fontSize: "30px",
        padding: "0 4px",
        borderRadius: "4px",
    };

    const handleMouseEnterIcon = (e: any) => {
        e.target.style.background = "#eaeaea";
    };
    const handleMouseLeaveIcon = (e: any) => {
        e.target.style.background = "transparent";
    };
    const handleMouseEnterCard = () => {
        setIsHover(true);
    };

    const handleMouseLeaveCard = () => {
        setIsHover(false);
    };
    const phaseBack = (pipelineRecord: Partial<IPipeline>) => (e: any) => {
        e.preventDefault();
        if (!pipelineRecord.dueDiligencePhase) return;
        const { closedWon, closedLost, archived } = pipelineRecord;

        const dueDiligencePhase = phases[phases.indexOf(pipelineRecord.dueDiligencePhase) - 1];
        updatePipeline({
            variables: { nanoid: pipelineRecord.nanoid, closedWon, closedLost, archived, dueDiligencePhase },
        });
    };

    const phaseNext = (pipelineRecord: Partial<IPipeline>) => (e: any) => {
        e.preventDefault();
        if (!pipelineRecord.dueDiligencePhase) return;
        const { closedWon, closedLost, archived } = pipelineRecord;

        const dueDiligencePhase = phases[phases.indexOf(pipelineRecord.dueDiligencePhase) + 1];
        updatePipeline({
            variables: { nanoid: pipelineRecord.nanoid, closedWon, closedLost, archived, dueDiligencePhase },
        });
    };

    const toggleCloseWon = (pipelineRecord: Partial<IPipeline>) => (e: any) => {
        // e.preventDefault();
        const { archived, dueDiligencePhase } = pipelineRecord;
        updatePipeline({
            variables: {
                nanoid: pipelineRecord.nanoid,
                closedWon: !pipelineRecord.closedWon,
                closedLost: false,
                archived,
                dueDiligencePhase,
            },
        });
    };

    const toggleCloseLost = (pipelineRecord: Partial<IPipeline>) => (e: any) => {
        // e.preventDefault();
        const { archived, dueDiligencePhase } = pipelineRecord;
        updatePipeline({
            variables: {
                nanoid: pipelineRecord.nanoid,
                closedWon: false,
                closedLost: !pipelineRecord.closedLost,
                archived,
                dueDiligencePhase,
            },
        });
    };

    const toggleArchive = (pipelineRecord: Partial<IPipeline>) => (e: any) => {
        // e.preventDefault();
        console.log("archive", pipelineRecord);
        const { closedWon, closedLost, dueDiligencePhase } = pipelineRecord;
        updatePipeline({
            variables: {
                nanoid: pipelineRecord.nanoid,
                closedWon,
                closedLost,
                archived: !pipelineRecord.archived,
                dueDiligencePhase,
            },
        });
    };

    const pipelineMenu = (pipelineRecord: any) => (
        <Menu onClick={(e) => {}}>
            <Menu.Item
                icon={<AiOutlineClose style={{ paddingRight: ".25rem", paddingTop: ".25rem", fontSize: "1.25rem" }} />}
                onClick={(e) => toggleCloseWon(pipelineRecord)(e)}
            >
                {pipelineRecord.closedWon ? "Unmark" : "Mark"} Close Won
            </Menu.Item>
            <Menu.Item
                icon={<AiOutlineCheck style={{ paddingRight: ".25rem", paddingTop: ".25rem", fontSize: "1.25rem" }} />}
                onClick={(e) => toggleCloseLost(pipelineRecord)(e)}
            >
                {pipelineRecord.closedLost ? "Unmark" : "Mark"} Close Lost
            </Menu.Item>
            <Menu.Item
                icon={<AiOutlineUsb style={{ paddingRight: ".25rem", paddingTop: ".25rem", fontSize: "1.25rem" }} />}
                onClick={(e) => toggleArchive(pipelineRecord)(e)}
            >
                {pipelineRecord.archived ? "Unarchive" : "Archive"}
            </Menu.Item>
        </Menu>
    );

    return (
        <Col xl={24} onMouseEnter={handleMouseEnterCard} onMouseLeave={handleMouseLeaveCard}>
            <Badge.Ribbon
                text={
                    performanceToAbbreviation(note.performance || "") === "PL"
                        ? `${ordinal(note.lienPosition ? +note.lienPosition : 0)}, PL ${loanTypeToAbbreviation(
                              note.noteType || ""
                          )}`
                        : `${ordinal(note.lienPosition ? +note.lienPosition : 0)}, NPL ${loanTypeToAbbreviation(
                              note.noteType || ""
                          )}`
                }
                color={performanceToAbbreviation(note.performance || "") === "PL" ? "green" : "red"}
            >
                <Card
                    size="small"
                    onClick={() => {}}
                    title={
                        <>
                            <Dropdown.Button
                                trigger={["click"]}
                                buttonsRender={([leftButton, rightButton]) => {
                                    return [
                                        null,
                                        <Button
                                            style={{
                                                background: "transparent",
                                                border: "0",
                                                textAlign: "right",
                                                paddingRight: ".5rem",
                                                paddingLeft: ".5rem",
                                            }}
                                        >
                                            <FaEllipsisV />
                                        </Button>,
                                    ];
                                }}
                                overlay={pipelineMenu(pipelineRecord)}
                            ></Dropdown.Button>
                            <Link style={{ color: "inherit" }} to={`/for-sale/${pipelineRecord.nanoid}`}>
                                {titleize(note.city || "")}, {stateNameToAbbreviation(note.state || "")}
                            </Link>
                        </>
                    }
                    // title={titleize(note.city || "") + ", " + stateNameToAbbreviation(note.state || "")}
                    actions={[note.listPrice ? formatter.format(note.listPrice) : "Make Offer", note.source]}
                    style={{
                        margin: "5px 0",
                        borderRadius: "4px",
                        // background:'rgb(0, 21, 41)',
                    }}
                >
                    {" "}
                    <Link style={{ color: "inherit" }} to={`/for-sale/${pipelineRecord.nanoid}`}>
                        <Row>
                            <Col span={2}>
                                {pipelineRecord.dueDiligencePhase !== "inbox" && isHover && (
                                    <Row>
                                        <AiOutlineArrowLeft
                                            onClick={phaseBack(pipelineRecord)}
                                            style={buttonNavigatorStyles}
                                            onMouseEnter={handleMouseEnterIcon}
                                            onMouseLeave={handleMouseLeaveIcon}
                                        />
                                    </Row>
                                )}
                            </Col>
                            <Col span={20}>
                                <div style={{ marginBottom: "10px" }}>
                                    {titleize(note.streetAddress || "")}, {note.zip}
                                </div>

                                <Row gutter={12} style={{ marginBottom: "10px" }}>
                                    <Col md={24} lg={12}>
                                        {(note.listPrice && note.upb && (
                                            <Tag
                                                color={itbColor(note.listPrice / note.upb, note.performance)}
                                                style={{ borderRadius: "4px" }}
                                            >
                                                <Statistic
                                                    title="ITB"
                                                    valueStyle={{ fontSize: "15px" }}
                                                    value={percentage(note.listPrice / note.upb)}
                                                />
                                            </Tag>
                                        )) || (
                                            <Tag style={{ borderRadius: "4px" }}>
                                                <Statistic title="ITB" valueStyle={{ fontSize: "15px" }} value={"--"} />
                                            </Tag>
                                        )}
                                    </Col>

                                    <Col md={24} lg={12}>
                                        {(note.listPrice && note.propertyValueSource && (
                                            <Tag
                                                color={itvColor(
                                                    note.listPrice / note.propertyValueSource,
                                                    note.performance
                                                )}
                                            >
                                                <Statistic
                                                    title="ITV"
                                                    valueStyle={{ fontSize: "15px" }}
                                                    value={percentage(note.listPrice / note.propertyValueSource)}
                                                />
                                            </Tag>
                                        )) || (
                                            <Tag style={{ borderRadius: "4px" }}>
                                                <Statistic title="ITV" valueStyle={{ fontSize: "15px" }} value={"--"} />
                                            </Tag>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            {isHover && (
                                <Col span={2}>
                                    <Row style={{ marginBottom: "5px" }}>
                                        {pipelineRecord.dueDiligencePhase !== "offer_accepted" && (
                                            <AiOutlineArrowRight
                                                onClick={phaseNext(pipelineRecord)}
                                                style={buttonNavigatorStyles}
                                                onMouseEnter={handleMouseEnterIcon}
                                                onMouseLeave={handleMouseLeaveIcon}
                                            />
                                        )}
                                    </Row>
                                    {/* <Row style={{ marginBottom: "5px" }}>
                                        <AiOutlineCheck
                                            onClick={closeWon(pipelineRecord)}
                                            style={buttonNavigatorStyles}
                                            onMouseEnter={handleMouseEnterIcon}
                                            onMouseLeave={handleMouseLeaveIcon}
                                        />
                                    </Row>
                                    <Row style={{ marginBottom: "5px" }}>
                                        <AiOutlineClose
                                            onClick={closeLost(pipelineRecord)}
                                            style={buttonNavigatorStyles}
                                            onMouseEnter={handleMouseEnterIcon}
                                            onMouseLeave={handleMouseLeaveIcon}
                                        />
                                    </Row>
                                    <Row style={{ marginBottom: "5px" }}>
                                        <AiOutlineUsb
                                            onClick={archive(pipelineRecord)}
                                            style={buttonNavigatorStyles}
                                            onMouseEnter={handleMouseEnterIcon}
                                            onMouseLeave={handleMouseLeaveIcon}
                                        />
                                    </Row> */}
                                </Col>
                            )}
                        </Row>
                    </Link>
                </Card>
            </Badge.Ribbon>
        </Col>
    );
};

export default PipelineDealItem;
