import React, { useState } from 'react';
import { Form, Button } from 'antd'
import { useMutation } from 'react-apollo';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { UPLOAD_CSV } from '../CRUD/Note.crud';
const { Dragger } = Upload;

const UploadTapeForm: React.FC<{}> = () => {
    const [uploadTape] = useMutation(UPLOAD_CSV)
    const [state, setState] = useState({ blob: null, source: '/upload-paperstac-csv' })
    const props = {
        name: 'file',
        multiple: true,
        action: process.env.REACT_APP_BASE_API_URL + state.source,
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <Form onFinish={(e) => { uploadTape({ variables: state }); setState({ ...state, blob: null }) }}>
            <Button onClick={() => { setState({ ...state, source: '/upload-paperstac-csv' }) }}>Paperstac</Button>
            <Button onClick={() => { setState({ ...state, source: '/upload-notesdirect-csv' }) }}>Notes Direct</Button>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Upload Tape to {state.source}
                </p>
            </Dragger>
            {/* <Button htmlType="submit">Upload Tape</Button> */}
        </Form>
    )
}

export default UploadTapeForm
