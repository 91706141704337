import React, { useContext } from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { Col, Layout, Menu, Row } from "antd";
import "./App.scss";
import "./App.less";
import "./styles/styles.scss";
import { setContext } from "apollo-link-context";
import { Link, useLocation, withRouter } from "react-router-dom";
import "types/index.d";
import { FileOutlined, UserOutlined, LogoutOutlined, LoginOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { GlobalContext, GlobalProvider } from "components/GlobalContext";
import { AppRoutes } from "./AppRoutes";
import Gravatar from "react-gravatar";
import "./helpers/fetchIntercept";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const API_URL = process.env.REACT_APP_BASE_API_URL;
const httpLink = createHttpLink({ uri: `${API_URL}/graphql` });

const authLink = setContext((_: any, headers: any) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("auth-token");
    // return the headers to the context so httpLink can read them

    return {
        headers: {
            // ...headers,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            Authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

const App = withRouter((props: any) => {
    return (
        <Layout style={{ minHeight: "100vh", minWidth: "1600px" }}>
            <GlobalProvider>
                <SideMenu />
                <Layout className="site-layout">
                    <ApolloProvider client={client}>
                        <AppHeader />
                        <Content>
                            {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
                            <AppRoutes />
                        </Content>
                        <Footer style={{ textAlign: "center" }}>Notesleuth, 2023</Footer>
                    </ApolloProvider>
                </Layout>
            </GlobalProvider>
        </Layout>
    );
});

const AppHeader: React.FC<IProps> = (props) => {
    const { state } = useContext(GlobalContext);

    const MenuContent = <Link to={"/logout"}>Logout</Link>;

    return (
        <Header className="site-layout-background" style={{ padding: "0px" }}>
            <Row>
                <Col span={12}>
                    <Header className="site-layout-background" style={{ paddingLeft: "10px" }}>
                        <img width={20} src="../logo512.png" alt="logo-notesleuth" /> Notesleuth
                    </Header>
                </Col>
                <Col span={12}>
                  <Row justify="end">
                    {state.currentUser.email && (
                        <Menu theme="dark" mode="horizontal" triggerSubMenuAction="click">
                            <SubMenu
                                key="SubMenu"
                                title={<Gravatar email={state.currentUser.email} style={{ borderRadius: "50%" }} />}
                            >
                                <Menu.Item key="1">{MenuContent}</Menu.Item>
                            </SubMenu>
                        </Menu>
                    )}
                    </Row>
                </Col>
            </Row>
        </Header>
    );
};

interface IProps {}
const SideMenu: React.FC<IProps> = (props) => {
    const { state } = useContext(GlobalContext);
    const location = useLocation();
    const screens = useBreakpoint();

    return (
        <Sider breakpoint="lg" collapsedWidth="80px">
            <Menu
                theme="dark"
                defaultOpenKeys={[`pipeline`]}
                defaultSelectedKeys={[`${location.pathname}`]}
                mode="inline"
                style={{ marginTop: "64px" }}
            >
                {/* {state.currentUser.nanoid && <Menu.Item key="for-sale" icon={<FileOutlined />}><Link to="/for-sale">For Sale</Link></Menu.Item>} */}
                {state.currentUser.nanoid && (
                    <Menu.Item key="deals" icon={<FileOutlined />}>
                        <Link to="/deals">My Deals</Link>
                    </Menu.Item>
                )}
                {/* {state.currentUser.nanoid &&
          <SubMenu key="pipeline" icon={<FileOutlined />} title="Your Deals">
            <Menu.Item key="/pipeline/inbox"><Link to={`/pipeline/inbox`}><InboxOutlined />Inbox</Link></Menu.Item>
            <Menu.Item key="/pipeline/interested"><Link to={`/pipeline/interested`}><StarOutlined /> Interested</Link></Menu.Item>
            <Menu.Item key="/pipeline/negotiating"><Link to={`/pipeline/negotiating`}><SwapOutlined /> Negotiating</Link></Menu.Item>
            <Menu.Item key="/pipeline/offer_accepted"><Link to={`/pipeline/offer_accepted`}><SnippetsOutlined /> Offer Accepted</Link></Menu.Item>
            <Menu.Item key="/pipeline/closed"><Link to={`/pipeline/closed`}><CheckCircleOutlined /> Closed</Link></Menu.Item>
            <Menu.Item key="/pipeline/archived"><Link to={`/pipeline/archived`}><DislikeOutlined /> Archived</Link></Menu.Item>
          </SubMenu>} */}
                {state.currentUser.role === "admin" && (
                    <Menu.Item key="/users" icon={<UserOutlined />}>
                        <Link to="/users">Users</Link>
                    </Menu.Item>
                )}
                {/* {state.currentUser.role === "admin" && (
                    <Menu.Item key="/upload-csv" icon={<UploadOutlined />}>
                        <Link to="/upload-csv">Upload CSV</Link>
                    </Menu.Item>
                )} */}
                {!state.currentUser.nanoid && (
                    <Menu.Item key="/login" icon={<LoginOutlined />}>
                        <Link to="/login">Login</Link>
                    </Menu.Item>
                )}
                {!state.currentUser.nanoid && (
                    <Menu.Item key="/create-account">
                        <Link to="/create-account">Register</Link>
                    </Menu.Item>
                )}

                {state.currentUser.nanoid && (
                    <SubMenu
                        key="my_account"
                        icon={<Gravatar size={20} email={state.currentUser.email} style={{ borderRadius: "50%" }} />}
                        title={screens.lg && <span style={{ marginLeft: ".4rem" }}>My Account</span>}
                    >
                        <Menu.Item key="/user">
                            <Link to={`/user/${state.currentUser.nanoid}`}>Info</Link>
                        </Menu.Item>
                        <Menu.Item key="billing">Billing</Menu.Item>
                        <Menu.Item key="prefernces">Preferences</Menu.Item>
                        <Menu.Item key="logout" icon={<LogoutOutlined />}>
                            <Link to="/logout">Logout</Link>
                        </Menu.Item>
                    </SubMenu>
                )}
                {state.currentUser.nanoid && (
                    <Menu.Item key="/verify-sources" icon={<CheckCircleOutlined />}>
                        <Link to="/verify-sources">Verify Sources</Link>
                    </Menu.Item>
                )}
            </Menu>
        </Sider>
    );
};

export default App;
