import gql from 'graphql-tag'

export const GET_USERS = gql`
    {
        users {
            nanoid
            firstName,
            lastName,
            email
        }
    }
`

export const GET_USER = gql`
    query GetUser($nanoid: String!){
        user(nanoid: $nanoid) {
            nanoid
            firstName,
            lastName,
            email
            role
        }
    }
`

export const UPDATE_USER = gql`
    mutation UpdateUser($nanoid: String!, $email: String!,  $firstName: String!,  $lastName: String!) {
        updateUser(input: {nanoid: $nanoid, email: $email, firstName: $firstName, lastName: $lastName}) {
            user {
                nanoid
                firstName,
                lastName,
                email
            }
            errors
        }
    }
`

export const DELETE_USER = gql`
   mutation DeleteUser($nanoid: String!){
       deleteUser(input: {nanoid: $nanoid}) {
           errors
       }
   }
`

export const CREATE_USER = gql`
  mutation CreateUser($firstName: String!, $lastName: String!, $email: String!, $password: String!) {
    createUser(input: {firstName: $firstName,lastName: $lastName, email: $email, password: $password})
    {
        token
        user {
            firstName
            lastName
            email
            nanoid
        }
    }
  }`

export const LOGIN_USER = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(input: {email: $email, password: $password})
    {
        token
        user {
            firstName
            lastName
            email
            nanoid
            role
        }
    }
  }`
